.background {
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: auto;
}
 .ModalWrapper {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
      background: #fff;
      color: #000;
      display: flex;
      grid-template-columns: 1fr 1fr;
      position: relative;
      z-index: 10;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 417px;
      max-width: 100%;
      padding: 20px;
 }

.ModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #141414;
    p {
      margin-bottom: 1rem;
    }
}
 .CloseModalButton {
    cursor: pointer;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-left: auto;
    
 }
 .close_btn{
    width: 32px;
    height: 32px;
 }
 .closemodalcontent{
   display: flex;
   align-items: center;
   justify-content: center;
   min-height: calc(100vh - 40px);
   margin: 20px auto;
 }

 .go_back{
  background-color: var(--primarycolor);
  border: 2px solid var(--text-white);
  border-radius: 8px;
 }