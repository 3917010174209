.form {
  margin-bottom: 0.1rem;
  padding: 50px 0;
}
.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  position: relative;
}
.formLabel {
  margin-bottom: 0.25rem;
  width: 100%;
}
.formField {
  background-color: white;
  padding: 0.5rem;
  padding-left: 10px;
  color: #000;
  border: 1px solid #ccc !important;
  outline: none !important;
  border-radius: 10px;
  height: 55px;
}
.formFieldError {
  background-color: white;
  border: 1px solid #ccc !important;
  height: 50px;
  padding-left: 10px;
  border-radius: 10px;
  color: #000;
  padding: 0.5rem;
  outline: none !important;
}

.formFieldErrorMessage {
  color: #e11d48;
  font-size: 12px;
  margin-bottom: 0rem !important;
  text-align: left;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.15rem;
}

.formSubmitBtn {
  padding: 15px 30px;
  border: none;
  min-width: 10rem;
  background-color: var(--primarycolor) !important;
  color: #f3e8ff;
  font-weight: 500;
  text-align: center;
  display: flex;
  border-radius: 10px;
  width: 20rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.password_link {
  color: var(--primarycolor);
  text-align: right;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.wel_head {
  text-align: center;
  margin-bottom: 30px;
}
.loginf_h6 {
  margin-top: 20px;
  text-align: center;
}
.para_term_policy {
  text-align: center;
  padding-top: 40px;
}
.a_ele {
  color: var(--primarycolor);
}
.loginlogo {
  margin: 0 auto;
  display: block;
}

.a_elem {
  color: var(--primarycolor);
  text-decoration: none;
  cursor: pointer;
}
.icon_password_login {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 22px;
  height: 50px;
}
.iicon_password_login {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 22px;
  height: 50px;
}
