.fbbutton {
  display: block;
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  color: white;
  border-radius: 8px;
  height: 40px;
  width: 140px;
  border: none;
}
.fbbutton p{
  padding-top: 8px;
  font-size: 14px;
}

.icon1 {
  color: var(--primarycolor);
  font-size: medium;
}
.rating {
  font-size: white;
}

.bfheading {
  color: white;
  font-weight: bold;
}
.bsheading {
  color: rgb(255, 255, 255);
  font-size: 50px;
  font-weight: bold;
}
.paragraph {
  color: rgb(255, 255, 255);
}
.ul-m {
  margin-left: 15px;
  display: inline-grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 20px;
  color: rgb(255, 255, 255);
  margin-bottom: 15px;
}
.textcontain {
  padding: 300px 0px 0px 20px;
  position: relative;
  z-index: 2;
}
.rating {
  color: rgb(255, 255, 255);
}
.Bannerfirst {
  background-image: url("../../assets/homebanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 55%;
  position: relative;
  width: 100%;
  height: 845px;
}

.Bannerfirst:after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
}