.history-card {
  background-color: var(--bg-color);
  color: white;
  min-height: 110px;
  max-height: 698px;
  border: 2px solid var(--cardbg);

}

.loss {
  background: red;
  padding: 10px;
  border-radius: 5px;
}

.after-nav-dsubheader-history {
  
  padding-top: 20px;
  background-color: var(--bg-color);

  min-height: calc(100vh - 382px);

}
.table_history{
  width: 100%;
  border-collapse: collapse;
  background-color: var(--bg-color);
  color: var(--text-offwhite);
  
}   
.table_headings th{
  background-color: var(--cardbg);
  border: none !important;
  height: 50px;
}
.table_body_tr td{
  border-bottom: 2px solid var(--cardbg);
  height: 60px;
}

.table_body_tr th{
  border-bottom: 2px solid var(--cardbg);
  height: 50px;
}
.table_headings th {
  padding-left: 35px;
}

.table_body_tr th{
  padding-left: 35px;
}
.table_body_tr td{
  padding-left: 35px;
}

.time_withdate{
  padding-top: 5px;
}

.win{
  background: green;
    padding: 10px;
    border-radius: 5px;
}

.history-card::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

  background-color:var(--bg-color);
}

.history-card::-webkit-scrollbar
{
	width: 10px;
  background-color:var(--bg-color);
}

.history-card::-webkit-scrollbar-thumb
{
	
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--cardbg);
  border-radius: 0px;

}

.history-card table tr th, td {
  white-space: nowrap;
}
.date_history{
  margin:6px 0px
}
.history_time{
  margin:6px 0px
}

.history-card {
  overflow: auto;
}
.paginatin_div{
  background-color: var(--bg-color);
    border: 1px solid var(--bg-color);
}

.not_status{
  background:var(--primarycolor);
    padding: 10px;
    border-radius: 5px;
}

.history-card {
  min-height: calc(100vh - 462px);
}

