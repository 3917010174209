.loadmbtn {
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: var(--bg-color);
  }

  .loadmbtn button {
    background-image: var(--btn-bg) !important;
    background-color: transparent !important;
    border: none;
    color: white;  
    margin-top: 30px;
    margin-bottom: 30px;
      width: 125px;
    height: 40px;
    border-radius: 6px;
  }