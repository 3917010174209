.main_subscription {
   width: 100%;
    max-width: 46rem;
    margin-bottom: 0.1rem;
    padding: 10px;
  }
  button.btn.btn-primary.freebtn {
    background-color: var(--primarycolor);
    border: 1px solid var(--primarycolor);
    color: var(--text-white);
  }

  .subscribe_btn{
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 6px;
    background-image: var(--btn-bg);
    color: var(--text-white);
  }

  .subscription_amount{
    font-size:50px;
    color: var(--primarycolor);
  }
  .subscription_amounts {
    position: relative;
}


  .benefits_points ul li{
    text-align: start;
    margin-top: 5px;
  }

  .subscribe_text_content h6{
    font-size: 14px;
  }


.doller {
  font-size: 20px;
  color: var(--primarycolor);
  position: absolute;
  top: 0px;
  right: 7.3em;
}

