.poll_moviename{
    width: 255px;
}

.poll_mymovies_nav-position{
    display: flex;
    gap: 96px;

}

.mymovies_item-positionn
{
    display: flex;
    gap: 100px;
}

.no_dropm_found{
    color: var(--text-black);
    padding: 10px;
}

.pool_tables{
    overflow: auto;
}

.pickups-input-groupp{
        width: 240px;
        background-color: var(--cardbg);
        height: 41px;
        color: white;
        border: 1px solid  var(--text-white) ;
    }


    .trade_movieslist_headings td select:focus {
        box-shadow: none !important;
        outline: none !important;
        border: 1px solid  var(--text-white) ;
       
    }