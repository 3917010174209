.row_items_watchlist {
  gap: 18px;
}
.watchl_text {
  padding-left: 20px;
  color: white;
  font-size: 20px;
}
.watchlist_cards {
  width: 100%;
  background-color: var(--bg-color);
  color: white;
  padding: 20px 20px 20px 20px;

}
.mmovielistcard {
  background-color: var(--bg-color);
  color: white;
}
.ccarddm {
  justify-content: space-evenly;
  background-color: var(--bg-color);
  --bs-card-spacer-x: 0rem !important;
  padding: 5px;
  --bs-card-border-radius: none !important;
}
.ccarddm.mmovielistcard{
  padding: 0;
}

.ccarddm .ccardimg {
  box-shadow: 2px 2px 2px 2px var(--cardbg);
  border-radius: 10px;
}
.mdetails {
  color: var(--text-offwhite);
}

.watchlisting_primain{
  background-color: var(--bg-color);
  border: 2px solid var(--cardbg);
  height: 100%;
}
.watchlist_img > img {

  box-shadow: 2px 2px 2px 2px var(--cardbg);
  width: 100% !important;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  object-fit: cover;
}
.watchlist_img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  position: relative;

}

svg.close_watchlist {
  position: absolute;
  top: -7px;
  right: -9px;
}

.cards_container{
  min-height: 1005px;
}

.watchlist_searchbox{
  padding-right:20px
}
 


.searchi-filed input{
  background: transparent;
}