.firstclass {
  color: rgb(255, 255, 255);
}
.secondclass {
  display: flex;
}
.rounded-start img{
  border-radius: 5px;
  width: 100%;
  height: 331px;
  object-fit: cover !important;
  cursor: pointer;
}
.rounded-start img:first-of-type{
  
  object-fit:none;
}
.card-title {
  color: rgb(255, 255, 255);
}
.top-c-t {
  color: var(--text-Offwhite);
cursor: pointer;
  line-height: 1.5;

  color: rgb(255, 255, 255);
}
.topn {
  background-color: (--bg-color);
}
.top-main {
  background-color: var(--bg-color);

  padding-bottom: 60px;
  padding-top: 30px;
}
.New_C_body {
  padding-top: 20px;
  /* padding-left: 20px; */
}
p.card-text.top-c-t {
  padding-top: 20px;
}


.topnewheading{
  cursor: pointer;
}
