.main_accordion{
    --bs-accordion-bg: var(--cardbg) !important;
    --bs-accordion-color:var(  --text-white)!important ;
}
.item_accordion{
   color:var(--text-white)!important ;
  
}

.accordion-button {
    color: var(--text-white) !important;
    
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
     background-color:var(--cardbg) !important;
}

.accordion {
    --bs-accordion-color: var(--bs-body-color);
     --bs-accordion-bg: var(--cardbg) !important;
   
     --bs-accordion-btn-icon: url("../../assets/keyboard_arrow_down.svg") !important;

     --bs-accordion-border-radius:0rem !important;
}

.accordion-body {
    color: white; 
}


.main_accordion .accordion-item .item_accordion .accordion-button:focus{

    box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/keyboard_arrow_down.svg") !important;;
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
}
.accordion-item {
  border: none !important;
    margin-bottom: 10px !important; 
}

.how_tostartitem{
    --bs-accordion-btn-icon:none !important
}
.how_tostartitem .accordion-button:not(.collapsed)::after {
    background-image:none!important;;
    transform:none !important;
}
.check_bodyy.accordion-body {
    background-color: var(--bg-color);
    border: 1px solid var(--cardbg);
}

 .accordion-button{
    border-radius: 10px !important;
} 

.accordion-item {
    border-radius: 10px !important;
    overflow: hidden;
}
.accordion-body{
    border-radius: 0px 0px 10px 10px !important;
}
.how_tostartitem button{
    font-size: 20px;
    font-weight: 500;
}