 .Background_Mcategory{
    width: 100%;
    background: rgb(0 0 0 / 17%);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow-y: hidden;
    z-index:10;
 } 

.ModalWrapper_Mcategory{
    width:100%;
    max-width: 500px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: flex;
    grid-template-columns: 1fr 1fr;
    position: relative;

    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 20px;
    z-index:1050;
}

 .ModalContent_Mcategory{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #141414;
    width:100%;
    p {
      margin-bottom: 1rem;
    }
 } 
  
.CloseModalButton_Mcategory{
    cursor: pointer;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-left: auto;

}
  
