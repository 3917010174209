.heading_live_listt{
    color: black;
}

.trade_iconn{
    width: 20px;
    margin-right: 15px;
}

.Trade_modal_btn{
    margin-top: 10px;
    justify-content: flex-end;
    display: flex;
}

.checkbox-enabled {
position: flex;
gap:1px;
  }
  
.checkbox-disabled {
    display: none;
  }

.Position_seconddivv_Trading {
    display: flex;
    justify-content: space-between;
    padding: 23px;
    border-bottom: 1px solid var(--primarycolor) !important;
}
.Player_listt{
    background-color: var(--text-white);
    color: black;
}

.Player_list{
    min-height: 65px;
    max-height: 350px;
    overflow: auto;
}
.mymovies_item-position {
    display: flex;
    gap: 120px;
}

.position_trading{
    border: 1px solid var(--cardbg);
    margin-top: 20px;
    background-color: #fff !important;
   color: #000 !important;
}

.table_containerr_trade{
    min-height: 100px;
    max-height: 400px;
    background-color: var(--text-white);
    color: black;
    overflow: auto;
    position: relative;
}


.playermovie_list{
    width: 100%;
    border-collapse: collapse;
    background-color: var(--text-white);
    color: black;
}

.trade_movieslist_headings td{
    background-color: var(--cardbg);
    border: none !important;
    height: 50px;
    color: white;
}

table.playermovie_list thead tr td {
    padding: 11px;
}

.table_containerr_trade thead{
    position: sticky;
    top: 0;
}

.heading_live_listt h6{
    text-transform: capitalize;
}
input[type="checkbox"]{
    background-color: #ffffff;
}
.checkcounter input[type="checkbox"] {
    accent-color: #0075ff;
}
.checkcounter input[type="checkbox"]:focus{
    border: 1px solid #000;
}
.checkcounter input[type="checkbox"]:checked:focus{
    border-color: transparent;
}
.pickupbtn_forAccommodate
{
    border: 1px solid var(--primarycolor);
    border-radius: 16px;
    background-color: var(--primarycolor);
    color: var(--text-white);
}

.moviesnamefornoname{
    color:var(--primarycolor);
    font-weight: bold;

}

