.blogC {
  background-color: var(--bg-color);
  padding: 0px 5px;
}

.news_div {
  background-color: var(--bg-color);
  padding-bottom: 0px;
}
.Jloadmbtn {
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  border: none;
  color: white;
  margin-left: 620px;
  margin-top: 60px;
  margin-bottom: 100px;
  width: 150px;
  height: 40px;
  border-radius: 6px;
}
.bblogsc {

  margin-top: 20px;
  
}

.newlisting_img > img {
  width: 100%;
  height: 101%;
  object-fit: cover;
}
.newlisting_img {
  width: 100%;
  min-height: 300px;
  max-height: 300px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 10px;
}
.jbimage {
  height: 300px;
}

.readbtn {
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  border: none;
  width: 130px;

  margin-top: 20px;
  margin-bottom: 20px;
}
.adbtn {
  background-color: var(--cardbg);
  border: none;
  color: var(--text-white);
}
.adbtn:hover {
  background-color: var(--cardbg) !important;
  border: none !important;
  color: var(--text-white) !important;
}
.anibtn {
  background-color: var(--cardbg);
  border: none;
}

.btnaora {
  display: flex;
  justify-content: unset !important;
  padding-top: 15px;
}
a.btn.btn-primary.anibtn {
  margin-left: 15px;
}

.twiiter_feed_div {
  position: sticky; 
  top: 0px;
  padding-top: 20px;
}

.shadow {
  position: relative;
  width: 420px;
  margin: 0 auto;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(128, 128, 128, 0),
    rgba(128, 128, 128, 1),
    rgba(128, 128, 128, 0)
  );
  margin-bottom: 10px;
}

.para_news {
  color: var(--text-offwhiteg);
}
.news_title {
  padding-top: 10px;
  cursor: pointer;
}

.read-more-btn{
position: absolute;
 bottom: 10px;
 margin-top: 20px;
 margin-bottom: 20px;
}

.new-listin-pra{
  min-height: 215px;
  position: relative;
}


.news_div::-webkit-scrollbar {
  display: none;
} 
  
.ads_google{
  padding-top: 20px;
}

.news_div{
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.mb-30{
  margin-bottom: 30px;
  min-height: 1200px;
}

.news-des-listing{
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}
.news-des-listing p:not(:last-child){
  margin-bottom: 10px;
}

.newsdesdata p{
  color: #fff !important;
}
.newsdesdata p span, .newsdesdata p strong{
  color: currentColor !important;
  background-color: var(--bg-color) !important;
}