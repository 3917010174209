 .Background_trade {
    width: 100%;
    background: rgb(0 0 0 / 39%);;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    top:0;
    position:fixed;
    z-index:1050;
    overflow: auto;
 }
  

 .ModalWrapper_trade {
     box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
     background: #fff;
     color: #000;
     display: flex;
     grid-template-columns: 1fr 1fr;
     position: relative;
     z-index: 10;
     border-radius: 10px;
     display: flex;
     flex-direction: column;
     align-items: center;
     max-width: 90%;
     padding: 20px;
     margin:0 auto;
     width: 1590px;
 }



.ModalContent_trade{
    display: flex;
    flex-direction: column;
    min-height:200px;
    color: #141414;
    width: 100%;

}


 .CloseModalButton_trade{
    cursor: pointer;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-left: auto;
}