.form {
  padding: 18px;
  margin: 0 auto;
  width: auto;
  
}
.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  position: relative;
}

.formLabel {
  margin-bottom: 0.25rem;
  width: 20rem;
}
.formField {
  background-color: white;
  padding: 0.5rem;
  padding-left: 10px;
  color: #000;
  border: 1px solid #ccc !important;
  outline: none !important;
  border-radius: 10px;
  height: 55px;
}

.formFieldError {
  background-color: white;
  border: 1px solid #ccc !important;
  height: 50px;
  padding-left: 10px;
  border-radius: 10px;
  color: #000;
  padding: 0.5rem;
  outline: none !important;
}

.formFieldErrorMessage {
  color: #e11d48;
  font-size: 12px;
  margin-bottom: 0rem !important;
  text-align: left;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.15rem;
}

.formSubmitBtn {
  padding: 15px 30px;
  border: none;
  min-width: 100%;
  background-color: var(--primarycolor) !important;
  color: #f3e8ff;
  font-weight: 500;
  text-align: center;
  display: flex;
  border-radius: 10px;
  width: 20rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.checkboxx {
  margin-right: 1px;
}
.logolink {
  text-align: center;
  margin-top: 10px;
}
.create_AC {
  text-align: center;
  padding-bottom: 10px;
}
.para_reg {
  text-align: center;
  padding-top: 10px;
  font-weight: 400;
}

.relogo {
  display: flex;
    margin: 0 auto;
}
.r_a_ele {
  color: var(--primarycolor);
}

.rr_aa_elem {
  color: var(--primarycolor);
  text-decoration: none;
  cursor: pointer;
}
.icon_password_login {
  position: absolute;
  right: 12px;
  top: 0px;
  width: 22px;
  height: 50px;
}

.cal_icon_register {
  position: absolute;
  right: 13px;
  top: 8px;
}
.iicon_password_login {
  position: absolute;
  right: 12px;
  top: 0px;
  width: 22px;
  height: 50px;
}

.formFieldd {
  padding: -1.6rem !important;
  color: #000000ab !important;
  background-color: white;
  padding-left: 10px;
  border: 1px solid #ccc !important;
  outline: none !important;
  border-radius: 10px;
  height: 55px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  padding-right: 1.5em;
}


.formGroup select:focus{
  box-shadow:none !important ;
}