.para_aboutus {
    color: var(--text-offwhite);
  }
  .para_aboutus1 {
    color: var(--text-offwhite);
    padding-top: 25px;
  }


.parent_aboutus {
  background-color: var(--bg-color);
  color: var(--text-white);
  padding: 25px;
}

.aboutus_secondbackground {
    background-image: url(../../assets//headerbannerimages.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
} 

.about-us-layer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(90deg, #003172 30%, rgba(1, 1, 1, 0.13) 100%);
}

.aboutus_fdiv{
    background-color: var(--bg-color);
}
.about_us_leadership{
    background-color: var(--bg-color);
}

.aboutus_leader_img {
    text-align: center;
}

.leadership-image {
    display: flex;
    justify-content: center;
    gap: 122px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.aboutus_leader_img img {
    border-radius: 50%;
    width: 280px;
    height: 280px;

}