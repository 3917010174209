.cards_div {
  background-color: var(--bg-color);
  padding-bottom: 160px;
}
.live_draft_div {
  background-color: var(--cardbg);
  color: white;
  width: 30rem;
}
.jjbcard-text {
  text-align: center;
  margin-top: 1300px;
}
.jjjbcard-text {
  text-align: center;
}
.howitwork_explore_banner {
  background-image: url("../../assets/bg_multi_movies\ 1.png");

  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 100%;
  height: 400px;
}
.card_B {
  padding-top: 30px;
  padding-bottom: 30px;
}
.card_tex {
  padding-top: 10px;
}

.JJloadmbtn {
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  border: none;
  color: white;
  margin-left: 872px;
  margin-top: 40px;
  margin-bottom: 100px;
  width: 150px;
  height: 40px;
  border-radius: 6px;
}
.Leadership {
  width: 100%;
  background-color: var(--bg-main);
  color: var(--text-white);
}
.Leadership_heading {
  text-align: center;
  justify-content: center;
  padding-top: 40px;
}
.Leadership_card {
  width: 18rem;
  margin: 90px;
  background-color: var(--bg-main);
}
.Leadership__card_body {
  text-align: center;
  justify-content: center;
  color: var(--text-white);
}
.howitwork_color_banner {
  background-image: url("../../assets/Rectangle\ 62.png");

  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 100%;
  height: 400px;
}
.cards_between_line {
  position: relative;
  width: 100%;
  height: 1px;
  margin-top: 205px;
  transform: rotate(90deg);
  background-color: grey;
}

.group_icon {
  position: absolute;
  top: 310px;
  display: flex;
  gap: 145px;
  width: 100%;
  transform: rotate(90deg);
}
.live_div {
  padding: 250px 0px 100px 350px;
}
.arrow_div {
  position: absolute;
  top: 750px;
  left: 840px;
}
.arrow_divv {
  position: absolute;
  top: 180px;
  right: 478px;
  transform: rotate(180deg);
}
.arrow_divvv {
  position: absolute;
  top: 575px;
  right: 478px;
  transform: rotate(180deg);
}

.pre_draft_div {
  padding: 50px 0px 0px 0px;

  position: absolute;
  left: -40px;
  display: grid;
  gap: 80px;
}
.years_d-v {
  position: relative;
}
.accodion_content{
  padding-top: 50px;
padding-bottom: 40px;
background-color: var(--bg-color);

}