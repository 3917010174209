.live-btn {
  background-color: var(--live-btn-bg);
  border: none;
  border-radius: 5px;
  width: 60px;
  color: white;
  font-size: 15px;

  font-style: italic;
}
.time_details {
  display: flex;
  gap: 60px;
}
.lives_buttons {
  display: flex;
  gap: 6px;
  margin-left: auto;
}

.live-section{
  position: relative;

}
button.live-btn {
  position: absolute;
  top: 0;
  right: -100px;
}