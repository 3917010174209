.edit_pro_sidebar {
  background-color: var(--bg-color);
  color: white;
  text-decoration: none;
  border: 2px solid var(--primarycolor);
  height: auto;
}
.edit_pro_largicon {
  padding-top: 50px;
  padding-bottom: 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.edit_pro_p {
  padding-top: 10px;
}
.edit_profie_a {
  display: grid;
  gap: 5px;
}
.myl-btn-editp {
  width: 100%;
  border: none;
  text-align: left;
  padding-left: 30px;
  color: white;
  height: 50px;
  padding-top: 15px;
  --bs-btn-border-radius: none;
}

.myl-btn-editp:hover {
  width: 100%;
  border: none;
  text-align: left;
  color: white;
}

.imag_set {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: contain;

}
.imag_set > img {
  width: 100%;
  height: 100%;
  object-fit: cover
}