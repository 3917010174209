 .Background_invites{
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    top:0;
    position:fixed;
    z-index:1050;
 }
  
 .ModalWrapper_invites{ 
  width: 650px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 20px;
}

.ModalContent_invites{ 
  display: flex;
  flex-direction: column;
  color: #141414;
  width: 100%;
  p {
    margin-bottom: 1rem;
  }}


.CloseModalButton_invites{ 
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-left: auto;
}