.navbar {
  color: white;
  background-color: var(--bg-color);
}

.filterse {
  background-color: var(--bg-color);
  color: white;
}

.sortbybtn {
  background-color: var(--bg-color);
  border-radius: 6px;
  color: white;
  border: 1px solid var(--text-white);
  width: 100px;
}

.fcim {
  background-color: var(--bg-color) !important;
  color: blue !important;
  border: 1px solid var(--text-white) !important;
}
.fcimm{
  background-color: var(--bg-color) !important;
  border: 1px solid var(--text-white) !important;
}
.movielist {
  background-color: var(--bg-color);
}
.showre {
  display: flex;
  justify-content: space-between;
}

.movielistcard {

  background-color: var(--bg-color);
  color: white;
  padding-bottom: 15px;
}

.movie_list_ul {
  display: flex;
  grid-template-columns: max-content max-content;
  grid-column-gap: 10px;
  color: rgb(255, 255, 255);
  padding-left: 0;
  flex-wrap: wrap;
}

.apply_btn{
  background-color: var(--bg-color);
}

.carddm {
  justify-content: space-evenly;
  background-color: var(--bg-color);
  --bs-card-spacer-x: 0rem !important;
  --bs-card-border-radius: none !important;
}
.Mcbody {
  color: white;
  margin-top: 15px;
}
.showre > p {
  margin-top: 0;
}
.cardimg {
  border-radius: 10px;
  transition: 200ms ease-in;
  cursor: pointer;
}
.carddm.movielistcard {
  padding-left: 0px;
  padding-right: 0px;
}
.carddm:hover {
  transition: all 0.3s ease;
  filter: grayscale(5%);
  transform: scale(0.95);
}
.carddm:hover .mcard-btn {
  display: block;
  filter: grayscale(1%);
  transform: scale(1);
}

.mcard-btn {
  position: absolute;
  top: 320px;
  left: 7px;
}

.mcard-btn,
.mcard-btn:hover {
  width: 94%;
  display: none;
  color: white;
  border: solid 1px white;
  z-index: 1;
  border-radius: 5px;
  background-color: #ffffff73;
}
.card-deck {
  justify-content: space-between;
}

.dropdown-menu.sortby-dropmenu {
  transform: none !important;
  top: 30px !important;
  right: 0 !important;
  left: inherit !important;
}
.mheading {
  margin-bottom: 5px;
}


.movielisting-div {
  margin-bottom: 20px;
}
.navlink {
  text-decoration: none;
}

.carddm .cardimg {
  box-shadow: 2px 2px 2px 2px var(--cardbg);
  width: 100% !important;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}
.cus_iamge {
  width:100%;
  height: 400px;
  object-fit: cover;
  position: relative;
}

.mcard-heart {
  position: absolute;
  top: 10px;
  width: 30px;
  height: 30px;
  right: 4px;
  fill: #e11d48;
}
.mcard-heartt {
  position: absolute;
  top: 45px;
  width: 30px;
  height: 30px;
  right: 4px;
  fill:white;
}
.textbody {
  margin-top: 15px;
}

span.showmorecategory {
  cursor: pointer;
}


.card-deck {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
}

button.filter-list-show {
  display: none;
}

button.filter-list-show {
  background-image: var(--btn-bg) !important;

  border: none;
  color: white;

  width: 101px;
  height: 40px;
  border-radius: 6px;
}
button.filter-list-show.filter-btn i {
  margin-right: 5px;
}

/* .fcim:checked {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

.fcim:not(:checked) {
  background-color: #f7f7f7;
  border-color: #ccc;
} */

/* .fcim {
  background-color: var(--bg-color);
} */

.apply_btn{
background-image: var(--btn-bg) !important;
border: none;
color: white;
width: 80px;
height: 30px;
border-radius: 5px;
}

.apply-btn-div{
  display: none;
}

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 222;
  cursor: pointer;
}

.text-offw{
  color: var( --text-offwhite);
}

.movie_list_ul span:not(:last-child):after{
  content: ',';
}

.movieheading_ele{
color: var(--text-white);
text-decoration: none;
}

