.chnage_submit_btn
{
    display: flex;
justify-content: center;
padding-top: 155px;
}

.icon_password {
position: absolute;
 top: 10px;
 right: 0;
 height: 18px;
 border: none;
 cursor: pointer;
 margin-right: 20px;
 margin-top: 38px;
 fill: var(--primarycolor);

}

.iicon_password_login {
    position: absolute;
    right: 12px;
    top: 0px;
    width: 22px;
    height: 50px;
  }
svg.icon_passwordd{
  position: absolute;
  top: 0;
  right: 0;
  height: 38px;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 38px;

}
input.sc-fHejqy.JRXBl {
padding: 5px;
}

span.navbar-brand.mb-0.h1.edit_text:hover{

    color: var(--text-white);
  
}
.changepass_btn{
  display: flex;
  justify-content: center;
  

}