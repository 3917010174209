.jbcard-text {
  text-align: center;
  letter-spacing: 2px;
}
.Lbanner {
  background-image: url("../../assets/headerbannerimages.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
} 

.card-img-overlay {
  display: block;
  height: 0 !important;
}