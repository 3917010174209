.css-b62m3t-container {
  width: 740px;
}

.cleague-main {
  background-color: var(--bg-color);
  color: white;
}

.inputf-control {
  background-color: var(--bg-color) !important;
  border: 1px solid var(--primarycolor);
  color: white;
  height: 55px;
  border-radius: 5px;
}
.inputff-control {
  background-color: var(--bg-color);
  border-color: var(--primarycolor);
  color: white;
  border-radius: 5px;
 resize: none;
}

.draft-input-group:hover {
  background-color: var(--bg-color);
  color: white;
  border: 1px solid var(--primarycolor);
}
.draft-input-group {
  background-color: var(--bg-color);
  height: 55px;
  color: white;
  border: 1px solid var(--primarycolor);
}
.css-1fdsijx-ValueContainer {
  border-color: var(--cardbg) !important;

  height: 50px;
}
.input-group-texticon {
  background-color: var(--bg-color);
  border: 2px solid var(--primarycolor);
}
.searchi {
  color: var(--primarycolor);
}
.css-13cymwt-control {
  background-color: var(--bg-color) !important;
  border: 2px solid var(--primarycolor) !important;
}


.img-upload-btn {
  position: absolute;
  width: 80px;
  top: 0;
  right: 0;
  height: 40px;
  padding: 5px 10px; /* Adjust padding as needed */
  background-image: var(--btn-bg) !important;
  background-color: transparent !important; /* Set background color as needed */
  border: none;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 40px;
  border-radius: 5px 5px 5px 5px;
  color: white;
}
.inputf-controlupload {
  color: white;
  background-color: var(--bg-color);
  border: 1px solid var(--primarycolor);
  color: white;
  padding-right: 100px;
  height: 56px;
  border-radius: 5px;
}
.cleague-nav {
  background-color: var(--cardbg);
}
.cleague {
  margin-left: 30px;
  color: white;
}
.input-container {
  position: relative;
  display: block;
}

.after-nav {
  background-color: var(--bg-color);

  padding-top: 10px;
  padding-bottom: 40px;
}
.form-createl {
  margin: 0px 30px 0px 30px;
}
.cleague-main {
  border: 1px solid var(--cardbg);
}
.cal-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.startdate-feild {
  position: relative;
}
.cal2-icon {
  position: absolute;
  right: 10px;
  top: 68%;
  transform: translateY(-50%);
}
.enddate-feild {
  position: relative;
}
.inputf-control::placeholder {
  color: var(--text-offwhite);
}
.inputf-controlupload::placeholder {
  color: var(--text-offwhite);
}
.inputff-control::placeholder {
  color: var(--text-offwhite);
}
.css-1xc3v61-indicatorContainer {
  color: var(--primarycolor) !important;
}
.inputf-control:focus {
  background-color: var(--bg-color);
  color: var(--text-offwhite);
}
.inputff-control:focus {
  background-color: var(--bg-color);
  color: var(--text-offwhite);
}
.inputf-controlupload:focus {
  background-color: var(--bg-color);
  color: var(--text-offwhite);
}
.c_leagueFieldErrorMessage {
  color: #e11d48;
  margin-bottom: 0rem !important;
  text-align: left;
}

.inputf-control:-webkit-autofill:hover,
.inputf-control:-webkit-autofill:focus,
.inputf-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--bg-color)  inset !important;
  border-radius: 5px !important;
  -webkit-text-fill-color: white !important;
}

.inputf-controlupload:-webkit-autofill:hover,
.inputf-controlupload:-webkit-autofill:focus,
.inputf-controlupload:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px var(--bg-color)  inset !important;
  border-radius: 5px !important;
  -webkit-text-fill-color: white !important;
}

/* Custom category select css */
.custom-preview{
  width: 100%;
  border-radius:5px;
  border:1px solid var(--primarycolor);
  padding: 5px ;
  cursor:pointer;
  display:flex;
  flex-wrap: wrap;
  min-height: 55px;
  gap: 5px;
   
}

.draft-input-group:focus{
  box-shadow: none !important;
  border:1px solid var(--primarycolor);
}
.inputff-control:focus{
  box-shadow: none !important;
  border:1px solid var(--primarycolor);
}
.inputf-controlupload:focus{
  box-shadow: none !important;
  border:1px solid var(--primarycolor);
}
.inputf-control:focus{
  box-shadow: none !important;
  border:1px solid var(--primarycolor);
}
.custom-dropdown {
  display: block;
  border: 1px solid #cccccc5e;
  border-radius: 5px;
  padding: 5px 0;
  width: 100%;
  max-height:200px;
  overflow-y: scroll;
  position: absolute;
  top: 57px;
  background: #000a16;
  border: 1px solid var(--primarycolor);
 
}

.custom-dropdown li {
  list-style: none;
  padding: 5px;
  margin-top:5px;
  display: flex;
  align-items: center;
}
.custom-arrow {
  position: relative;
  top: 5px;
  margin-left: 1000px;
  /* color: var(--text-offwhite); */
 
}
.custom-dropdown li:hover {
  background-color: var(--primarycolor);
}

#m-name{
  display: inline-block;
}

.custom-displaySelection {
  padding: 5px 9px;
  border-radius: 5px;
  display: inline-block;
  background: var(--cardbg);
  display: flex;
  align-items: center;
}

.cross {
  color: #ff5050;
  margin: 0 0 0 10px;
}

[type="checkbox"],label,li{
  cursor: pointer;
}

.arrow{
  width:100%;
  padding-right:30px;
  display: flex;
  justify-content: space-between;
}


.close_icon{
  fill: var(--primarycolor);
  width: 12px;
}

.custom{
visibility: hidden;
}


.custom-dropdown label{
  width: 100%;
}


input.inputf-control:-webkit-autofill,
input.inputf-control:-webkit-autofill:hover,
input.inputf-control:-webkit-autofill:focus,
input.inputf-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #000a16 inset !important;
  -webkit-text-fill-color: #fff !important;
  border-radius: 5px;
}

span.navbar-brand.mb-.h1.cleague:hover{
  color: var(--text-white);
}
.custom-dropdown::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

  background-color:var(--bg-color);
}

.custom-dropdown::-webkit-scrollbar
{
	width: 10px;
  background-color:var(--bg-color);
}

.custom-dropdown::-webkit-scrollbar-thumb
{
	
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--cardbg);
  border-radius: 5px;

}
