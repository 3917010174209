.greatericon {
  font-size: 10px;
  margin-bottom: 2px;
}
.viewheading {
  color: var(--text-white);
  text-decoration: none;
}
.cardd {
  justify-content: space-evenly;
  background-color: var(--bg-color);
  --bs-card-spacer-x: 0rem !important;

  --bs-card-border-radius: none !important;
}
.cbodyheading {
  color: white;
}
.cbodyheading h5 {
  cursor: pointer;
}
.cardd:hover {
  transition: all 0.3s ease;
  filter: grayscale(5%);
  transform: scale(0.95);
}
.cardd:hover .card-btn {
  display: block;
  filter: grayscale(1%);
  transform: scale(1);
}

.slick-prev,
.slick-next {
  width: 20px !important;
  height: 80px !important;
  background-color: var(--primarycolor) !important ;
  top: 38% !important;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
}
.slick-prev:before,
.slick-next:before {
  line-height: 4 !important;
}

.Tren_m_ul {
  display: flex;
  grid-template-columns: max-content max-content;
  grid-column-gap: 10px;
  color: rgb(255, 255, 255);
  padding-left: 0;
  flex-wrap: wrap;
}
.section_main {
  position: relative;
  padding-top: 60px;

  background-color: var(--bg-color);
}
.slick-next {
  transform: translate(-250%, -50%) !important;
}
.slick-prev {
  transform: translate(250%, -50%) !important;
}

.mdetails {
  margin-top: 10px;
  color: var(--text-offwhite);
}

.heading {
  color: var(--text-white);
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.first {
  background-color: var(--bg-color);
}

.card-btn {
  position: absolute;
  top: 300px;
}

.card-btn,
.card-btn:hover {
  width: 90%;
  margin-left: 18px;
  border-color: var(--text-offwhite);
  display: none;
  color: var(--text-offwhite);
  z-index: 1;
  border-radius: 5px;
  background-color: #ffffff73;
}
.cbodyheading {
  margin-top: 15px;
}

.con_div {
  width: 100%;
  padding-left: 20px;
  padding-right: 25px;
}
.imgw-100 {
  border-radius: 12px;
  width: 100%;
  height: 400px;
  object-fit: cover;
  cursor: pointer;

}
 .first_card{
  padding-left:10px;
} 

.loading{
  background-color: var(--bg-color);
  color: var(--text-white);
}
.slick-slide.slick-active.slick-current {
  margin-left: -7px;
}

.Tren_m_ul l:not(:last-child):after{
  content: ',';
}

.Tren_m_ul span:not(:last-child):after{
  content: ',';
}