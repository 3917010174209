.trade_icon{
    width: 15px;
    color: white;
}

.trade-btns {
    background-image: var(--btn-bg) !important;
    background-color: transparent !important;
    color: white;
    border: none;
    width: 100px;
    height: 36px;
    gap: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }

  .trading1_btns {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    flex-wrap: wrap;
}