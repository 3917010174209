.nav-editprofile {
  background-color: var(--cardbg);
}
.edit_text {
  color: white;
  padding-left: 20px;
}
.edit_pro_inpfields {
  width: 100%;

  background-color: var(--bg-color);
  color: white;
  padding: 20px 15px 0px 20px;
}

.edit_pro_input {
  background-color: var(--bg-color);
  border: 1px solid var(--primarycolor);
  color: var(--text-offwhite);
  height: 50px;
  border-radius: 5px;
  text-transform: capitalize;
}
.edit_pro_input::placeholder {
  color: var(--text-offwhite);
}
.edit_primain {
  background-color: var(--bg-color);
  margin-left: 40px;
  border: 2px solid var(--cardbg);
  height: 600px;
  height: 100%;
}
.edit_pro_input:focus {
  background-color: var(--bg-color);
  color: var(--text-offwhite);
}
.edit_submit_btn {
  display: flex;
  justify-content: center;
margin-top: 10px;
margin-bottom: 20px;
}
select.w-100.select_div {
  height: 50px;
  border-radius: 5px;
  background-color: var(--bg-color);
  color: var(--text-white);
  border: 1px solid var(--primarycolor);
  padding-left: 12px;
  border: 1px solid var(--primarycolor);
}
.secondrow_div{
 padding-top: 30px;
}

select.w-100.select_div > option {
  color: var(--text-white);

}
.zedo > :first-child {
  padding-top: 0px;
}

select.w-100.select_div:focus:not(:focus-visible) {
  box-shadow: none !important;
}

.edit_pro_input:focus{
  box-shadow: none !important;
  border:1px solid var(--primarycolor);
}
.select_div:focus{
  box-shadow: none !important;
  border:1px solid var(--primarycolor);
}
.disable_option{
  display: none;
}

input.edit_pro_input:-webkit-autofill,
input.edit_pro_input:-webkit-autofill:hover,
input.edit_pro_input:-webkit-autofill:focus,
input.edit_pro_input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #000a16 inset !important;
  -webkit-text-fill-color: #fff !important;
  border-radius: 5px;
}

.navbar-brand.mb-0.h1.edit_text:hover{

  color: var(--text-white);

}

