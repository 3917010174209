.parent_contactus {
  color: var(--text-white);
  background-color: var(--bg-color);

  padding: 142px;
}
.ccontact_subinput {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.27);
  color: var(--text-white);
  height: 140px;
  resize: none;
}
.contact_subinput {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.27);
  color: var(--text-white);
  height: 50px;
}
.contact_subinput:focus{
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.27);
  color: var(--text-white);
  height: 50px;
  box-shadow:none !important;
  outline: none !important;
}


.icons_contactus {
  display: inline-flex;
  gap: 10px;
  padding-top: 20px;
}

.subinput_field {

  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.contactus-btn {
  padding-top: 30px;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2em;
}
.contactus-btnn {
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  color: var(--text-white);
  border-color: var(--text-white);
  
}
.contactus-btnn:hover{
  border: 1px solid var(--text-white);
}
.parent_contactus {
  background-image: url("../../assets/contactbg.png");
  background-repeat: no-repeat;
  background-size: cover;

 min-height: calc(100vh - 378px);

}
.para-contactus {
  margin-top: 30px;
  width: 360px;
}

.ccontact_subinput:focus{
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-white);
  border-color: rgba(255, 255, 255, 0.27);
  box-shadow:none !important;
  outline: none !important;
}

.contact_subinput:-webkit-autofill,
.contact_subinput:-webkit-autofill:hover,
.contact_subinput:-webkit-autofill:focus,
.contact_subinput:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(35 43 54 ) inset !important;
  -webkit-text-fill-color: #fff !important;
  border-radius: 5px;
}

.submit_btn{
  border: 1px solid var(--text-white);
  border-radius:5px;
display: inline-block;
}
