.subheader-nav {
  background-color: var(--cardbg);

  border-radius: 5px;
}
.btns-subheader {
  display: flex;
  justify-content: unset !important;
  margin-left: 15px;
  gap: 15px;
}

a.btn.btn-primary.myl-btn {
  margin-left: 10px;
}
.btn-primary:hover {
  background-color: var(--primarycolor) !important;
}
.active {
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  border-color: transparent;
}
.subheader-btn {
  background-color: var(--card-color);
  color: white;
  padding: 11px 20px;
}
.subheader-btn.active {
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  border-color: transparent;
}
.btn-primary {
  border: none;
}
.myleague_items_div {
  background-color: var(--cardbg);
}
.items_myleague {
  color: white;
}
.navLinkWithoutDecoration {
  text-decoration: none;
}
.items_myleague:active {
  background-color: var(--primarycolor) !important;
}
.items_myleague:hover {
  background-color: var(--primarycolor) !important;
  color: var(--text-white);
}
