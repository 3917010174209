.btns_category button {
    background-color: var(  --text-white);
    color: var(--primarycolor);
    border: 1px solid var(--primarycolor);
    border-radius: 5px;
    margin-right: 5px;
}

.btns_con{
    display: flex;
}

.parent{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

}

.category_heading h4{
    margin-bottom: 20px;
}
.main_category{
    width: 100%;
}