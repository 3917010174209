.jdetailsM {
  background-color: var(--bg-color);
}
.blogCc {
  background-color: var(--bg-color);
  --bs-card-spacer-y: none !important;
}

.adbtn {
  background-color: #253b57;
  border: none;
}
.anibtn {
  background-color: #253b57;
  border: none;
}

.btnaora {
  display: flex;
  justify-content: unset !important;
}
a.btn.btn-primary.anibtn {
  margin-left: 15px;
}
.ppcbody {
  color: white;
}
.categories {
  color: white;

}

.commentse {
  background-color: var(--bg-color);
  /* padding: 0px 6px; */
}
.ppp {
  color: white;
  margin-bottom: 20px;
}
.formcomment {
  color: white;
}
.headingpara {
  color: white;
}
.ntextarea {
  background-color: #0a1f38;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  width: 98%;
  height: 50px;
  padding-left: 10px;
}
.ctextarea {
  background-color: #0a1f38;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  width: 100%;
  padding: 10px;
  resize: none;
 
}
.ctextarea:focus{
  border: none !important;
}
.email_div .etextarea:focus{
  border: none !important;
  outline: none !important;
}
.neinut .ntextarea:focus{
  border: none !important;
  outline: none !important;
}
.etextarea {
  background-color: #0a1f38;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  width: 100%;
  height: 50px;
  padding-left: 10px;
}
.cbtn {
  height: 45px;
  margin-top: 40px;
  margin-bottom: 80px;
}
.para_div {
  font-size: 20px;
  padding-top: 20px;
}
.cards_tags {
  padding-top: 20px;
}
.ppimg {
cursor: pointer;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
}
.ppcbody h6 {
  font-size: 14px;
}
.para_text_Jdate {
  padding-top: 2px;
  color: var(--text-offwhite);
}
.ppcontent {
  padding: 0px 0px 0px 25px;
}
.jdetails_row {
  padding-top: 15px;
  padding-bottom: 15px;
}
.categories {
  padding-top: 70px;
  padding-bottom: 20px;
}
.a_ele_categ {
  padding-top: 15px;
  color: var(--text-offwhite);
  text-decoration: none;
}
.neinut {
  margin-top: 5px;
}

.new {
  display: flex;
  overflow-x: hidden;
}
.text-body-third {
  color: var(--text-white);
  margin-top: 10px;
}
.imag_div > img {
  width: 100%;
  height: auto;
}
.imag_div {
  min-width: 100%;
  max-width: 400px;
  overflow: hidden;
  border-radius: 10px;
  height: auto;
}
.card-img-overlay {
  top: 0;

}

.commnets_divs {
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 5px;

}

.d-flex.text-white.name_time {
  justify-content: space-between;
  margin-bottom: 16px;
}
.commnet_section {
  padding-top: 20px;
  padding-bottom: 30px;
}

.c_text{
  border: none !important;
  width: 100%;
}

.comment_text:after {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  background: var(--cardbg);
  top: 20px;
  left: -10px;
  rotate: 45deg;
}
.comment_text {
  background-color: var(--cardbg);
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
.profileiconnn {
    width: 60px;
    height: 60px;
    border-radius: 50px;
}

.date_time_comment{
  color:gray;
}

.commnet_sendername{
  font-weight: 600;
  text-transform: capitalize;
}



.formcomment textarea:focus{
outline: none !important;
} 

.pp_card_title{
  text-decoration: none !important;
  font-size: 14px;
  cursor: pointer;
  
}

.new_comment{
  min-height:10px;
  max-height: 600px;
  overflow: auto;
}

.new_comment::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

	background-color:var(--bg-color);
}

.new_comment::-webkit-scrollbar
{
	width: 5px;
	background-color:var(--bg-color);
}

.new_comment::-webkit-scrollbar-thumb
{

	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--cardbg);
}
.pp-text{
  color: var(--text-offwhiteg);
}

span.btn.adbtn_news_details {
  background-color: #253b57 !important;
  color: var(--text-white);
  border: none;
}

span.btn.adbtn_news_details:hover {
  background-color: #253b57 !important;
  color: var(--text-white) !important;
  border: none !important;
}