@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
body {
  font-family: "Poppins", sans-serif !important;
}
.swal-text{
  text-align: center;
}
.checkregister{
  display: flex;
  gap: 10px;
  font-size: 14px;
  user-select: none;
  text-align: left;
}
.checkregister input{
  font-size: 18px;
}
.googleads_style{
  display: block;
  width: 100%;
}
.Trademark{
  font-size: 55%;
  margin-left: 2px;
  font-weight: normal;
}
.Trademark2{
  font-size: 55%;
  margin-left: 2px;
  font-weight: normal;
  top: -8px;
}
.Trademark3{
  font-size: 55%;
  margin-left: 2px;
  font-weight: normal;
  top: -10px;
}
:root {
  --primarycolor: #286ac0;
  --cardbg: #253b57;
  --blackbg: #000000;
  --bg-color: #000a16;
  --bg-main: #000a16;

  /* text-color */
  --text-primarycolor: #286ac0;
  --text-white: #ffffff;
  --text-offwhite: #e6e6e6;
  --text-offwhiteg: #d6d6d6;
  --text-offwhitegg: #d6d6d6ab;
  --text-placeholder:#AEAEAE;
  
  /* button bg */
  --btn-bg: linear-gradient(
    265deg,
    #2269c5 1.69%,
    #4573ab 51.34%,
    #2269c5 99.64%
  );
  --live-btn-bg: #f60000;
  --bg-color-btncategory: #081e3a;
}

/* input:-webkit-autofill, */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #000 !important;
  border-radius: 10px;
}

.modal.hide .close {
  display: none;
}
input[type="checkbox"]{
  accent-color: #0075ff;
}

.scrollbarcstm::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.scrollbarcstm::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.scrollbarcstm::-webkit-scrollbar-thumb {
  background: var(--text-primarycolor); 
  border-radius: 10px;
}

/* Handle on hover */
.scrollbarcstm::-webkit-scrollbar-thumb:hover {
  background: var(--text-primarycolor); 
}


.MuiMultiSectionDigitalClock-root ul::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.MuiMultiSectionDigitalClock-root ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.MuiMultiSectionDigitalClock-root ul::-webkit-scrollbar-thumb {
  background: var(--text-primarycolor); 
  border-radius: 10px;
}

/* Handle on hover */
.MuiMultiSectionDigitalClock-root ul::-webkit-scrollbar-thumb:hover {
  background: var(--text-primarycolor); 
}


/* trading1 page css */l
.trading1_btns{
  display: flex;
  gap: 10px;
}


.ttrade-btns{
background-image: var(--btn-bg) !important;
background-color: transparent !important;
color: white;
border: none;
width: 140px;
height: 36px;
gap: 5px;
border-radius: 5px;
}

.ttrade-btns-reject{

 background-color: #FFA8A8  !important; 
  color: red;
  border: none;
  width: 123px;
  height: 36px;
  gap: 5px;
  border-radius: 5px;
  }
  
  .ttrade-btns-accept{
 
    background-color: #86FFB6 !important;
    color: green;
    border: none;
    width: 138px;
    height: 36px;
    gap: 5px;
    border-radius: 5px;
    }
    
  /* changepasssowrd */
    .icon_password_login {
      position: absolute;
      right: 12px;
      top: 0px;
      width: 22px;
      height: 50px;
    }


    .App {
      overflow: hidden;
      padding-top:71px ;
  }

    .no_league_found{
      padding-top: 10px;
      padding-left: 15px;
      color: var(--text-white);
    }
    .no_Movies_found {
      padding-top: 10px;
      padding-left: 5px;
      color: var(--text-white);
  }
    .otpInput {
      width: 40px;
      height: 40px;
      margin: 5px;
      text-align: center;
      font-size: 1.2em;
      background-color: var(--text-white);
      border: 1px solid var(--blackbg);
      color: var(--text-black);
    }
    .tradingmdetails_wrapper.ModalWrapper_trade {
      padding: 20px;
      margin: 0 auto;
      width: 1400px;
      max-width: 95%;
  }
  .ModalWrapper_trade .M_details_Card{
    max-height: 76vh;
    overflow: auto;
  }
  .ModalWrapper_trade .moviesd_bannerimg{
    max-height: 76vh;
  }
 


  .Background_Mcategory > div {
    width: 520px;
    max-width: 100%;
}
.Background_Mcategory .CloseModalButton_Mcategory{
  margin-bottom: -30px;
  position: relative;
  z-index: 1;
}
.btn-disabled{
  opacity: .5;
}


    /* Responsive   1440px*/
@media only screen and (min-width: 1281px) and (max-width: 1440px){
  .movielisting-div .row .col-xxl-2 {
    flex: 0 0 auto;
    width: 25%;
  }
  .navdropdown{
    margin-left: 30px;
  }
}
@media (max-width:1580px){
  .second_position {
    display: flex;
    padding-left: 7px !important;
    gap: 11px;
    height: 43px;
}
.draft-input-groupp {
  width: 188px;
}
.search_div {
  max-width: 190px;
}
}
  @media (max-width:1440px) {
    .btnaoraa {
      display: flex;
      gap: 5px;
  }

    .div_items {
      width: 20%;
    }
 

    .Post_text {
      padding: 4px 10px;
  }
    .pp_image{
      padding: 0;
    }
    .search_div {
      max-width: 190px;
  }
    .second_position {
      display: flex;
      padding-left: 7px !important;
      gap: 11px;
      height: 43px;
  }
  .draft-input-groupp {
    width: 188px;
  }
  .joinleaguetable td, .joinleaguetable th {

    width: 24%;
}
.Position_seconddivvvvvv_players {
  display: flex;
  align-items: center;
  padding-left: 33px;
  padding-bottom: 21px;
  gap: 54px;
}
.d-flex.first_Sec_Sec {
  padding-left: 0;

}
    .score_graph{
      overflow: auto;
  }
 
    .row_items_watchlist {
      gap: 13px;
  }
    .row.categories h5 {
      padding-left: 0;
  }
  .row.categories a {
      padding-left: 0;
  }
    .col-lg-3.col-md-12.pp_image {
      padding: 0px 10px 0px 0px;
  }
    .col-md-9.input_fcontactus {
      padding-left: 44px;
  }
.para-contactus {
  margin-top: 30px;
  width: 98%;
}
    .cast_crew_body {
      gap: 6px;
    }
    .movie_list_ul{
      grid-column-gap: 11px;
    }
    .side-nav-bar-links .navdropdown {
      margin-left: 0px;
    }
    .Bannerfirst{
      height: 75vh;
    }
    .textcontain {
      padding: 190px 0px 0px 20px;
    }
  
  }



    /* Responsive   1366px*/
  @media (max-width:1366px) {
    .cast_crew_body {
      gap: 17px;
  }
    .Bannerfirst {
      background-position: 0 44%;
     }

     .navbar-content {
      gap: 2px;
     }
     .btnaoraa {
      display: flex;
      gap: 5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px){
  .searchcategorydraft{
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }
  .searchcategorydraft .second_position{
    padding-left: 0 !important;
    width: 100%;
  }
  .searchcategorydraft .draft-input-groupp{
    width: 100%;
  }
  
  .searchcategorydraft .search_div{
    max-width: calc(50% - 5.5px);
    width: 100%;
  }
  .searchcatfil{
    max-width: calc(50% - 5.5px);
    width: 100%;
  }
}
@media (max-width:1280px){
  .nav_items .nav_slider_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 10px;
  }
  .nav-content-itmes {
    display: none ;
}
.navbar-content{
  width: auto;
  margin-left: auto;
}
}

  /* Responsive   1024*/
  @media (max-width:1024px) {
    .Background_trade{
      display: inline-block;
    }
    .trading_wrapper, .trading_wrapper_pickuping{
      margin: 30px auto !important;
    }
    .movielistcard{
      position: relative;
    }

    .aboutus_secondbackground{
      height: auto !important;
    }
    .aboutus_secondbackground .about-us-layer{
      padding: 50px 15px;
    }
    .moviecard{
      display: flex !important;
      flex-direction: column;
    }
    .moviecard > *:first-child{
      order: 1;
    }
    .moviecard .card-cstm-btn{
      order: 3;
      display: block;
      margin: 0;
      width: 95%;
      margin-left: 2.5%;
      position: relative;      
      top: inherit;
      left: 0;      
    }
    .moviecard .moviebody{
      order: 2;
    }
     
    .alert-icon {
      padding-right: 10px;
     
  }
    .tradingmdetails_wrapper.ModalWrapper_trade{
      width: 1050px;
      height: 98vh;
      overflow: auto;
    }
    .ModalWrapper_trade .M_details_Card{
      max-height: 100%;
      
    }
    .ModalWrapper_trade .moviesd_bannerimg{
      max-height: 100%;
    }

    .trading_wrapper {
      max-width: 98% !important;
      padding: 20px;
      margin: 0 auto;
   
  }
  .trading_wrapper_pickuping{
    max-width: 97% !important;
    padding: 20px;
   margin: 0 auto;

  }
    .movie-details-content
    {
      padding: 11px 0px 0px 0px;
    }
    .leadership-image {
      display: flex;
      justify-content: center;
      gap: 10px !important;
      align-items: center;
      flex-wrap: wrap;
  }
    .aboutus_leader_img img {
      width: 220px !important;
      height: 220px !important;
  }
  
  
    .ppcbody {
      padding-right: 7px;
  }
    .cards_tags {
      flex: none  !important;
    }
    .para_div {
      flex: none  !important;
    }
    .table_containerr_trade{
      overflow-x: auto;
    }
    .heading_live_listt{
      padding-top: 20px;
    }
    .search_div input::placeholder {
      font-size: 13px;
    }

    .Position_Picklist_items {
      align-items: center;
    }
    .draft-input-groupp {
      font-size: 12px;
  }
  .btnaoraa button {
    font-size: 13px;
}
    
.Post_text {
  padding: 4px 2px;
}

    .otherplyer_list h6 {
      margin: 16px 0 0 0;
  }
    .league-card-content {
    padding: 8px 10px;
}
    .row.categories h5 {
      padding-left: 0;
  }
  .row.categories a {
      padding-left: 0;
  }
    .ppimg {
      width: 92%;
      max-width: 228px;
      height: 100%;
     
    }
  .para-contactus {
    margin-top: 30px;
    width: 221px;
}
.parent_contactus h1 {
  font-size: 34px;
}

    .card_m_video{
      background-position: -110px 35%;
    }
    .cast_crew_body {
      gap: 12.5px;
    }
  .nav-content-itmes {
    display: none ;
}
.div_items{
  width: auto !important;
}
.nav_items .nav_slider_btn{
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-content{
  width: auto;
}
.navbar-expand-lg .navbar-collapse {
  flex-wrap: wrap !important;
}
.Bannerfirst{
  height: 52vh;
}
.textcontain {
  padding: 115px 0px 0px 20px;
}
.para_news {
  font-size: 14px;
}
.news_title{
  font-size: 18px;
}
  }

 
  @media (min-width:768px) and (max-width:991px) {
    .tradingmdetails_wrapper.ModalWrapper_trade{
      width: 750px;
      height: 98vh;
      overflow: auto;
    }
    .ModalWrapper_trade .M_details_Card{
      max-height: 100%;
      
    }
    .ModalWrapper_trade .moviesd_bannerimg{
      max-height: 100%;
    }
    .trading_wrapper_pickuping{
      max-width: 80% !important;
      padding: 20px;
     margin: 0 auto;
    }
    .afterdashboard_nav
    {min-height: calc(100vh - 703px);}
  
    .leadership-image {
      gap: 20px !important;
    } 
    .overflow_h{
    overflow: hidden !important;
  }
    .items_navbar {
      gap: 10px;
  }
    .after_row_div {
      padding-right: 27px;
  }
    .cast_card{
      width: 10rem;
    }
    .ppcontent {
      padding: 0px 0px 0px 17px;
  }
  button.filter-list-show {
    display: block;
}
.content_live {
  padding: 8px 0px !important;
}
.movie-details-content {
  padding: 0;
}
.card_m_video {
  background-position: 0;

}
.card_m_video {
  margin-bottom: 12px;
}
.div_items{
  width: auto !important;
}
  .firstchildclass {
    position: absolute;
    z-index: 999;
    background: #000;
    border-radius: 0%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.9px);
    border: 1px solid var(--text-white);
    transition: all ease-in-out 0.5s;
    padding: 12px;
    background: var(--bg-color);
    backdrop-filter: blur(20px);
    width: 96%;
    left: -96%;
    max-height: 752px;
    overflow: auto;
    top: 10%;
}

.filter-show{
   top: 10%;
   left: 16px;
   border-radius: 5px;
}
.movie-details-content {
  height: 100%;

}
.apply-btn-div {
  display: block;
}
}

  
 /* Responsive   768px*/
  @media (max-width:768px) {
    .dleague-card-nav-span {
      display: flex;
      color: white;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
  }
    .watchlist_img {
      height: 270px;
  }
    .Lbanner {
      height: 310px;
    }
    .leadership-image {
      display: flex;
      justify-content: center;
      gap: 10px !important;
      align-items: center;
      flex-wrap: nowrap !important;
  }
    .aboutus_leader_img img {
      width: 180px !important;
      height: 190px !important;
  }
 
    .aboutus_row{
      padding: 35px;
      }

    .App {
      overflow: hidden;
      padding-top:71px ;
  }
    .apply-btn-div{
      display: block;
    }
    .ppimg {
      max-width:96%;    
    }
    .ppcbody {
      padding-right: 5px;
  }
    .ppimg {
      width: 100%;
      height: 100%;
      padding-right: 5px;
  }

.table_containerr_trade{
  overflow: auto;
}

 
    .content_live{
      padding:9px 26px 1px 0px !important;
  }
    .trading_wrapper{
      max-width: 95% !important;
      padding: 20px;
     margin: 0 auto;

    }
   
    .second_position select{
      font-size: 14px;
    }

    .search_div input::placeholder {
      font-size: 14px;
    }

    .btnaoraa button {
      font-size: 14px;
      text-wrap: nowrap;
    }  
    .league-card-content {
      padding: 9px 26px 1px 11px;
  }
  
    .label_payment {
      font-size: 14px;
  }
    .after-nav-dsubheader-history{
      padding-bottom: 50px;
    }
    .items_navbar {
      gap: 10px
    }
    .form-label {
      font-size: 14px;
  }
    .imag_div > img {
      width: 100%;
      height: auto;
  }
    .imag_div{
      min-height: auto;
    }
    .parent_contactus {
      padding: 50px 20px;
  }
    .pp_card_title {
      font-size: 14px;
    }
    .Post_text {
    padding: 0px !important;
    margin-top: 4px;
}
    .ppp{
    font-size: 20px;
  }
    .card_m_video {
      background-position: -85px 35%;
  }
    .movie-details-content {
      height: 100%;
      padding: 9px 0px;
    }
    button.filter-list-show {
      display: block;
  }
  .div_items{
    width: auto !important;
  }
  
  .navdropdown{
 margin-left: 0;
  }

  .Bannerfirst {
    height: 40vh ;
    display: flex;
    align-items: center;
  }

  .textcontain {
    padding:0px 0px 0px 15px; 
}
.edit_pro_inpfields {
  padding: 7PX;
}

.col-lg-6.col-xl-4.input-container {
  display: block;
}

}

@media (max-width:767px){
  .searchcategorydraft{
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }
  .searchcategorydraft .second_position{
    padding-left: 0 !important;
    width: 100%;
  }
  .searchcategorydraft .draft-input-groupp{
    width: 100%;
  }
  
  .searchcategorydraft .search_div{
    max-width: calc(50% - 5.5px);
    width: 100%;
  }
  .searchcatfil{
    max-width: calc(50% - 5.5px);
    width: 100%;
  }
}


 /* Responsive   540px*/
  @media (max-width:575px) { 
    .cus_iamgecstm{
      height: 310px;
    }
    .tradingmdetails_wrapper.ModalWrapper_trade{
      width: 550px;
      height: 98vh;
      overflow: auto;
    }
    .ModalWrapper_trade .M_details_Card{
      max-height: 100%;
      
    }
    .ModalWrapper_trade .moviesd_bannerimg{
      max-height: 100%;
    }
    .photo_div_images {
      width: 100%;
      height: 100%;
  }
    .trading_wrapper_pickuping {
      max-width: 95% !important;
      padding: 20px;
      margin: 0 auto;
  }
    .trading_wrapper {
      max-width: 95% !important;
      padding: 20px;
      margin: 0 auto;
  }
    .leadership-image {
      display: flex;
      justify-content: center;
      gap: 0px;
      flex-wrap: wrap !important;
    } 
    .para-contactus {
      width:100%;
  }
    .input_fcontactus{
      padding-top: 10px;
    }
   
    .contactus_email{
      padding-top: 10px;
    }
  .firstchildclass {
  position: absolute;
  z-index: 999;
  background: #000;
  border-radius: 0%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.9px);
  border: 1px solid var(--text-white);
  transition: all ease-in-out 0.5s;
  padding: 12px;
  background: var(--bg-color);
  backdrop-filter: blur(20px);
  width: 95%;
  left: -95%;
  max-height: 600px;
  overflow: auto;
  top: 10%;
}
 .filter-show{
top: 10%;
 left:11px;
 border-radius: 5px;
} 

    .App {
      overflow: hidden;
      padding-top:71px ;
  }
  .filter-show{
     
     border-radius: 5px;
  }
   
    .time_with_date {
 
      gap: 11px;
    }

    .ntextarea{
      width: 100%;
    }
    .blockitem {
      display: block;
    }
    span.data.mt-1 {
      font-size: 13px;
  }

.font_time{
  font-size: 13px;
}
   .second_position {
      padding-left: 0px !important;
    }
   
    .ppimg {
      padding-right: 0;
  } 
    .new {
      display: inline-block;
      overflow-x: hidden;
  }
  .ppcontent {
    padding: 25px;
}
  .ppimg {
    max-width: 100%;
}
    .carddm .cardimg {
      object-fit: fill;
}
    .trading_wrapper_invite{
      max-width: 85% !important;
      padding: 20px;
      margin: 0 auto;
    }
    .rmodal_wrapper{
      overflow: auto;
      height:96vh;
    }
    span.navbar-brand {
      width: calc(100% - 150px);
    }
   

    .blogCc .card-body{
      flex:none ;
    }

    .Position_seconddivvvv{
      flex-wrap: wrap;
      gap: 10px;

    }
    .second_position {
      padding-left: 0px;
    }
    .d_l_c_content{
      padding-left: 0;
    }

    .table-containerr{
      font-size: 13px;
    }
    .watchlisting_primain {
margin-top: 15px;
      margin-left: 0px;
  
  }

  .payment-sec{
    margin-top: 15px;
    margin-left: 0px !important;
  }
    .edit_primain {

      margin-left: 0px;
  margin-top: 15px;
  }
  .edit_primain {

    height: auto;
}
    .dleague-card-nav-span {
      flex-wrap: wrap;
      gap: 12px;
  }
    .live-section {
      margin-right: 7rem;
  }
    .league-card-content {
   gap: 10px;
  }
   

.name_with_btn{
  margin-right: 5px;
}
.alert-icon .badge{
  right: -4px;
}


  .label_payment {
    font-size: 12px;
}
    .btns-subheader{
      margin-left: 5px !important;
      gap:2px;
    }
    .subheader-btn {
      font-size: 14px;
  }
  
  div#dropdownMenu1 svg {
    margin: 7px;
}
  .alert-icon img {
    margin: 0;
}
  .alert-icon {
    display: flex;
    width: 100%;
    justify-content: space-between;
    min-width: 210px;
}
    .text-body-third{
      font-size: 13px;
    }
    .col-lg-3.col-md-12.pp_image {
      padding: 0px 14px 3px 0px;
  }
  
    .imag_div > img {
      height: 100%;
    }


    .imag_div {
   min-height: 0px;
    }

    .row.categories h5 {
      padding-left: 0;
  }

  .row.categories a {
      padding-left: 0;
  }

.csutomcdd {
      font-size: 13px;
  }

    .date_time_comment {
      color: gray;
      font-size: 12px;
      margin-left: 12px;
      font-weight: 500;
  }
  
    .second_review{
      padding-left: 0px;
      padding-bottom: 10px;
    }

    .cast_crew_body {
      gap: 9px;
    }

    .card_m_video{
      background-position: -180px 35%;
    }

.New_C_body {
      padding-bottom: 20px;
  }
.Bannerfirst{
      height: 28vh;
    }
.textcontain {
      padding: 0px 0px 0px 18px;
  }
.textcontain p {
    font-size: 12px;
}
.textcontain h6 {
  font-size: 14px;
}
.textcontain h1 {
  font-size: 24px;
}
.ul-m li {
  font-size: 12px;
}

.hide-btn{
  display: block !important;
}

.sbtext h6 {
  font-size: 14px;
}
.sbtext h1 {
  font-size: 18px;
}
.sbtext h4 {
  font-size: 14px;
}
.Bannersecond{
  padding: 0;
  height: auto;
  height: 200px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

}
.main-bsimg{
  height: auto;
  padding: 0;
}
.New_C_body {

    padding-left:7px;
}
.banner-content {
  display: flex;
  justify-content: center;
}

.user_name {
  font-size: 14px;
  width: 100%;
  text-wrap: nowrap;
  margin-left: 5px;
}

svg#dropdownMenuButton {
  margin-right: 2px !important;
}
.dropdown-menu.notification-dropmenu {
  transform: none !important;
  top: 50px !important;
  left: -35px !important;
  width: 282px;
  max-height: 174px;
  }


  .twiiter_feed_div {
    padding-bottom: 20px;
}

.Dob_div{
  margin-top: 10px;
}
.secondrow_div{
  padding-top: 10px;
}
.ordercompleted{
  margin: 10px 0 0;
  width: 100%;
}
.moviecard .card-cstm-btn{
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
}
.user_name{
  display: none;
}
.nav_items .nav_slider_btn{
  min-width: 39px;
}
  }

 /* Responsive   430px*/
@media (max-width:430px) {
  #Checkout {
    width: 90% !important;
  }

  .payment_form {
     padding: 8px !important;
  }
  .form-group.pt-2.pb-2 {
    padding-left: 20px;
}
  .alert-icon{
    padding-right: 0;
  }

  .moviecard .card-cstm-btn {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .slick-prev, .slick-next {

    top: 28% !important;
    
}
  .cus_iamgecstm{
    height: 290px;
  }
  .trending-movie1 .imgw-100{
    height: 270px;
  }
  .categories {
padding-top: 10px;
  }
  .doller {
    right: 6.1em;
  }
  .tradingmdetails_wrapper.ModalWrapper_trade{
    width: 370px;
    height: 98vh;
    overflow: auto;
  }
  .ModalWrapper_trade .M_details_Card{
    max-height: 100%;
    
  }
  .ModalWrapper_trade .moviesd_bannerimg{
    max-height: 100%;
  }
.user_name{
    display: none;
  }
  .watchlist-icon{
    font-size: 12px;
  }
  .leadership-image {
    gap: 0px !important;
  } 
  .payment-sec{
    margin-top: 15px;
    margin-left: 0px !important;
  }
  .para-contactus {
    margin-top: 10px;
    width: 100%;
}
  .input_fcontactus{
    padding-top: 10px;
  }
  .contactus_email{
    padding-top: 10px;
  }

  .card_m_video{
    height: 305px;
  }
  .App {
    overflow: hidden;
    padding-top:58px ;
}
  .overflow_h{
    overflow: hidden !important;
  } 

  .firstchildclass {
    position: absolute;
    z-index: 999;
    background: #000;
    border-radius: 0%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.9px);
    border: 1px solid var(--text-white);
    transition: all ease-in-out 0.5s;
    padding: 12px;
    background: var(--bg-color);
    backdrop-filter: blur(20px);
    width: 95%;
    left: -95%;
    max-height: 640px;
    overflow: auto;
    top: 10%;
    
}
.filter-show{
  top: 3%;
   left:11px;
   border-radius: 5px;
}

  .custom-detail {
    position: absolute;
    right: 0px;
}
  .email_div{
    padding-top: 10px;
  }
  input.form-control.mr-sm-2.joinl_search{
    padding-right: 3px;
  }
  .trading_wrapper_invite{
    max-width: 57% !important;
    padding: 20px;
    margin: 0 auto;
  }
  .trading_wrapper {
    max-width: 95% !important;
    padding: 20px;
    margin: 0 auto;
}
.trading_wrapper_pickuping {
  max-width: 95% !important;
  padding: 20px;
  margin: 0 auto;
}
.trading1_btns{
  flex-wrap: wrap;
}
  .form {
    margin-left: 29%;

}
.RegisterForm_formFieldErrorMessage__v1nVm {
  height: 7px;
}
  .dleague-card-tdate{
    gap:12px;
  }
  span.dleague-card-nav-span button {
    text-wrap: nowrap;
}
  .time_with_date{
    flex-wrap: wrap;
  }
  .notifiction_item {
    color: black;
    white-space: normal;
    font-size: 12px;
}
.dropdown-menu.notification-dropmenu {
  transform: none !important;
  top: 45px !important;
  left: -113px !important;
  width: 274px;
  max-height: 120px;
}
.alert-icon {
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-width: 175px;
}
.name_with_btn{
  margin-right: 0;
}
.alert-icon img{
  margin-left: auto;
}
.side-nav-bar-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
}

.dropdown-menu.arrow_dropmenu {
  top: 57px !important;
  right: 8px !important;
}


.alert-icon .badge {
  right: -8px;
  top: -2px;
}
    a.btn.btn-primary.subheader-btn {
      padding: 6px 6px;
  }
  .comment_text {
    padding: 15px;
  }
  .side-nav-collpase{
    right: 10px;
  }
  .card_m_video {
    background-position: -84px 35%;
}
  .cast_crew_body {
    gap:22px
  }
  .cast_card {
    width: 9rem;
  }
  .Bannerfirst{
    height: 220px;
    background-position: center;
  }
 
  .navdropdown{
    margin-left: 0;
  }
  .New_C_body {

    padding-bottom: 20px;
} 
span.navbar-brand img {
  width: 30px;
}

span.navbar-brand b {
  font-size: 14px;
}
.nav_items .nav_slider_btn {
  background: transparent;
  height: 34px;
  width: 34px;
  min-width: 34px;
  padding: 0;
}
.nav_items .nav_slider_btn i {
  font-size: 20px;
} 
.alert-icon span svg {
  width: 28px;
}
svg#dropdownMenuButton {
   margin-right: 0px !important;
}
.profileicon {
  width: 27px;
  height: 27px;
}
.profileicon {
  border-radius: 50%;
  margin-right: 4px;
}
.con_div {

  padding-left: 10px;
  padding-right: 10px;
}
.user_name {
  font-size: 12px;
  margin-left: 5px;
}
.btns-subheader {
  flex-wrap: wrap;
}
.btns-subheader {
  gap: 6px;
  margin-left: 15px !important;
}
.watchlist_searchbox {
  margin-top: 5px;
  margin-left: 15px;

}
.btn.cat {
  font-size: 14px;
}
.Lbanner{
  height: 170px;
}
.trading_wrapperc{
  
  margin: 0 auto;
  padding: 20px;

}
.cccarddm .cccardimg {
  height: 270px;
}
.google-adsmovielist{

  width: 100%;
}
}


@media (max-width:320px) {


  .google-adsmovielist{

    width: 100%;
  }
  .trading_wrapper_invite {
    max-width: 50% !important;
    padding: 20px;
    margin: 0 auto;
}
  #Checkout {
    width: 90% !important;
  }

  .payment_form {
     padding: 7px !important;
  }
  .form-group.pt-2.pb-2 {
    padding-left: 20px;
}
  .cccarddm .cccardimg {
    height: 270px;
}
  .trading_wrapperc{
    margin: 0 auto;
    padding: 20px;
  
  }
  .Lbanner{
    height: 150px;
  }
  .otpInput {
    width: 35px !important;
  }
  .tradingmdetails_wrapper.ModalWrapper_trade{
    width: 330px;
    height: 98vh;
    overflow: auto;
  }
  .ModalWrapper_trade .M_details_Card{
    max-height: 100%;
    
  }
  .ModalWrapper_trade .moviesd_bannerimg{
    max-height: 100%;
  }
  .firstchildclass {
    position: absolute;
    z-index: 999;
    background: #000;
    border-radius: 0%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.9px);
    border: 1px solid var(--text-white);
    transition: all ease-in-out 0.5s;
    padding: 12px;
    background: var(--bg-color);
    backdrop-filter: blur(20px);
    width: 95%;
    left: -95%;
    max-height: 536px;
    overflow: auto;
    top: 10%;
  }

  .trading_wrapper {
    max-width: 90% !important;
    padding: 20px;
    margin: 0 auto;
}

.trading_wrapper_pickuping {
  max-width: 90% !important;
  padding: 20px;
  margin: 0 auto;
}
  .payment-sec{
    margin-top: 15px;
    margin-left: 0px !important;
  }
  .leadership-image {
    gap: 0px !important;
  } 
  .para-contactus {
    margin-top: 10px;
    width: 100%;
}
  .input_fcontactus{
    padding-top: 10px;
  }
  
  .contactus_email{
    padding-top: 10px;
  }
  .App {
    overflow: hidden;
    padding-top:58px ;
}
  .user_name {
    font-size: 10px;
}
.firstchildclass{
     top: 0 !important;
}
.filter-show{
   top: 2%!important;
   left:8px;
   border-radius: 5px;
}
 .textcontain p {
        font-size: 10px;
    }
  .btns-subheader {
    gap: 5px;
}

.watchlist_searchbox {
  margin-top: 5px;
  margin-left: 15px;

}
  .dropdown-menu.notification-dropmenu {
    transform: none !important;
    top: 45px !important;
    left: -130px !important;
    width: 274px;
    max-height: 120px;
}
  
  .second_position{
    flex-wrap: wrap;
    height: auto;
  }
  .three_Item{
    font-size: 12px;
  }
  .btns-subheader {
    flex-wrap: wrap;
}

  .alert-icon {
    min-width: 170px;
}
  .cast_card .mdetails{
font-size: 12px;
  }
  .cast_crew_body {
    gap: 29px;
}
  .btn.cat{
    font-size: 13px;
  }
  .cast_card {
    width: 7rem;
}
  .card_m_video {
    background-position: -115px 35%;
}
.LoginForm_form__OtlVj {
  padding: 30px 0;
}
.bCXHJT{
  padding: 5px !important;
}
.RegisterForm_form__Kq9X7{
  width: 338px !important;
}
.rmodal_wrapper{
  height: 98vh;
}
.main_subscription {
  padding: 18px 0;
}
.doller {
  right: 5.5em;
}

#Checkout{
  min-width: 285px !important;
}
}


@media only screen 
  and (min-width: 820px) 
  and (min-height: 1180px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .movie-details-content
    {
      padding: 11px 0px 0px 0px;
    }
    .textcontain {
      padding: 155px 0px 0px 20px;
  }
  .Bannerfirst {
    height: 44vh;
}
  .apply-btn-div {
    display: block;
}

 .news_div::-webkit-scrollbar {
  display: none;
} 
.para-contactus {
  margin-top: 30px;
  width: 202px;
}

}


@media only screen 
  and (min-width: 540px) 
  and (max-height: 720px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .Bannerfirst {
    height: 44vh;
}
}


@media only screen 
  and (min-width: 1024px) 
  and (max-height: 600px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .Bannerfirst {
    height: 74vh;
}
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .apply-btn-div {
      display: none;
  }
  }

  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .doller {
      right: 7.1em;
    }
    .overflow_h{
      overflow: hidden !important;
    } 
  
    .movielisting-div .row .col-xxl-2 {
      flex: 0 0 auto;
      width: 50%;
  }
    .d_l_c_content{
      padding-left: 0px !important;
    }
    .Bannerfirst {
      height: 90vh!important;
  }
  
    .col-md-8.col-lg-9.col-xl-10 {
      padding-top: 10px;
  }
      .payment-sec{
        margin-left: 0px !important;
      }
      .watchlisting_primain {
        margin-left: 0px;
    }
    .edit_primain {
      margin-left: 0px;
  
  }
    .Bannersecond{
      background-size: 252% 80% !important;
    }
    .banner-content {
      justify-content: center !important;
  }
  .firstchildclass {
    position: absolute;
    z-index: 999;
    background: #000;
    border-radius: 0%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.9px);
    border: 1px solid var(--text-white);
    transition: all ease-in-out 0.5s;
    padding: 12px;
    background: var(--bg-color);
    backdrop-filter: blur(20px);
    width: 96%;
    left: -96%;
    max-height: 392px !important;
    overflow: auto;
    top: 10%;
}
.filter-show{
  top: 2%;
  left: 16px;
  border-radius: 5px;
}
.tradingmdetails_wrapper.ModalWrapper_trade {
  width: 95%;
  height: 98vh;
  overflow: auto;
}
.trading_wrapper_pickuping {
  max-width: 97% !important;
  padding: 20px;
  margin: 0 auto;
}

.trading_wrapper {
  height: 98vh;
  overflow: auto;
}
  }
  
  @media only screen and (orientation: landscape) and (max-width: 667px)  {
    .doller {
      right: 7.1em;
    }
    .overflow_h{
      overflow: hidden !important;
    } 
  
    .d_l_c_content{
      padding-left: 0px !important;
    }
    .Bannerfirst {
      height: 90vh!important;
  }
  
    .col-md-8.col-lg-9.col-xl-10 {
      padding-top: 10px;
  }
      .payment-sec{
        margin-left: 0px !important;
      }
      .watchlisting_primain {
        margin-left: 0px;
    }
    .edit_primain {
      margin-left: 0px;
  
  }
  .Bannersecond{
    background-size: 100% 80% !important;
  }
  .banner-content {
    justify-content: center !important;
}
.tradingmdetails_wrapper.ModalWrapper_trade {
  width: 95%;
  height: 98vh;
  overflow: auto;
}
.trading_wrapper_pickuping {
  max-width: 97% !important;
  padding: 20px;
  margin: 0 auto;
  overflow: auto;
  height: 98vh;
}
.trading_wrapper {
  height: 98vh;
  overflow: auto;
}
.firstchildclass {
  position: absolute;
  z-index: 999;
  background: #000;
  border-radius: 0%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.9px);
  border: 1px solid var(--text-white);
  transition: all ease-in-out 0.5s;
  padding: 12px;
  background: var(--bg-color);
  backdrop-filter: blur(20px);
  width: 96%;
  left: -96%;
  max-height: 350px !important;
  overflow: auto;
  top: 10%;
}
.filter-show{
top: 4%;
left: 16px;
border-radius: 5px;
}
  }


  @media only screen and (orientation: landscape) and (max-width: 568px)  {
     .overflow_h{
    overflow: hidden !important;
  } 
  .doller {
    right: 7.1em;
  }
    .firstchildclass {
      position: absolute;
      z-index: 999;
      background: #000;
      border-radius: 0%;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(1.9px);
      border: 1px solid var(--text-white);
      transition: all ease-in-out 0.5s;
      padding: 12px;
      background: var(--bg-color);
      backdrop-filter: blur(20px);
      width: 96%;
      left: -96%;
      max-height: 296px !important;
      overflow: auto;
      top: 10%;
  }
  .filter-show{
    top: 4%;
    left: 16px;
    border-radius: 5px;
  }
    .col-lg-3.col-md-3.col-sm-4.ppcontent {
      width: 33% !important;
  }
  .blogCc.card.col-lg-9.col-md-9.col-sm-8 {
    width: 66% !important;
}
.categories {
  padding-top: 25px;
  padding-bottom: 20px;
}
.Lbanner {
  height: 240px;
}

  }