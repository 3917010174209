.detail-card {
  background-color: var(--bg-color);
  color: white;
  margin-bottom: 80px;
  border: 2px solid var(--cardbg);
}

.navbar-detail {
  padding: 20px 20px 20px 20px;
  background-color: var(--cardbg);
  color: white;
  width: 100%;
}

.navdetails-content {
  display: inline-flex;
  gap: 578px;
}

.nav-content2 {
  display: inline-flex;
  gap: 290px;
}

.league-detail {
  margin-top: 20px;
  margin-bottom: 10px;
  display: inline-flex;
  gap: 255px;
  margin-left: 20px;
}

.league-deatailstwo {
  display: inline-flex;
  gap: 320px;
  margin-bottom: 10px;
}

.Bench {
  background-color: #5799ec;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  width: 55px;
  margin-left: 5px;
  border: none;
}

.league-details {
  margin-top: 20px;
  padding-left: 20px;
  display: inline-flex;
  gap: 192px;
  margin-bottom: 10px;
}

.league-deatailstwoo {
  display: inline-flex;
  gap: 320px;
  margin-bottom: 10px;
}

.btn-detail {
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  border: none;
  width: 140px;
  height: 40px;
  border-radius: 5px;
  color: white;
  margin-bottom: 80px;
}

.d_l_c_content {
  position: relative;
  padding-left: 30px;
  padding-top: 10px;
}

.time-detail-detailpag {
  margin-top: 20px;
}

.dleague-card-tdate {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
  margin-top: 5px;
  gap: 10px;
}
.mt-20 {
  margin-top: -20px;
}

.league_time {
  display: flex;
  gap: 490px;
  position: relative;
}
.detleague-card-img {
  width: 120px;
  height: 155px;
  border-radius: 8px;
  object-fit: cover;
}

.detail-PP-btn {
  margin-top: 32px;
  background-color: var(--primarycolor);
  width: 110px;
}
.main-row {
  position: relative;
  margin: 20px 20px 20px 20px;
}

.labels {
  color: white;
}

.custom-detail {
  position: absolute;
  right: 5px;
}


.history-hrr {
  margin: 0px;
  background-color: 2px solid var(--primarycolor);
  margin-left: 20px !important;
  margin-right: 20px !important;
}


.drag_drop_icon{
fill: white;
  width: 20px;
  height: 30px;
}

.nav-main{
  background-color: var(--cardbg);
  padding: 20px;
   justify-content: unset;
   /* gap: 590px; */
   flex-wrap: wrap;
}

.Draft_orderitem {
  display: flex;
  color: var(--text-white);
  padding: 20px 0px;
  transition: all .3s ease;
  position:relative;
}

.Position_seconddivvv_info{
  display: flex;
  gap: 41px;
  background-color: var(--bg-color);
  color: var(--text-white);
  padding: 20px 20px 8px 20px;
}

.Position_seconddivvvvvv {
  display: flex;
 align-items: center;
}

.Position_sec {
  background-color: var(--bg-color);
  color: var(--text-white);
  padding: 10px 20px 0px 20px;
}

.d-flex.total_div {
  padding-top: 10px;
  justify-content: space-between;
}

.profileiconn{
  width: 80px;
  height: 80px;
  border-radius: 50px;

}
.playerimg{
  width: 50px;
  height: 50px;
}

.name_profile{
  text-align: center;
}

.draft-input-groupp {
  width: 240px;
  background-color: var(--bg-color);
  height: 41px;
  color: white;
  border: 1px solid var(--primarycolor);
}

.draft-input-groupp:focus {
box-shadow: none !important;
outline: none !important;
}

.second_position {
  display: flex;
  /* padding-left: 151px; */
  gap: 5px;
}

.Position_seconddivvvv{
  display: flex;
  justify-content: space-between;
  background-color: var(--bg-color);
  color: var(--text-white);
  padding: 20px 20px 0px 20px;

}

.text_context{
  padding-left: 25px;
}

.second_positionn{
  display: flex;
  padding-left: 595px;
  gap: 37px;
}

.Position_seconddivvvvv {
  display: flex;
  justify-content: space-between;
  background-color: var(--bg-color);
  color: var(--text-white);
  padding: 20px 20px 10px 20px;
}


.profile_icon {
  border-radius: 10%;
  margin-right: 10px;
  height: 58px;
  width: 60px;
  object-fit: cover;
}

.first_div{
display: flex;
align-items: baseline;
max-width: 300px;
}
.third_div {
  display: flex;
}

.d-flex.first_Sec_Sec {
  padding-left: 14px;
}

.After_draft_div{
  padding-bottom: 40px;
  
}

input.form-control.mr-sm-2.joinl_search {
  background: var(--bgcolor);
  color: white;
  border: 1px solid var;
  border: 1px solid var(--primarycolor);
  height: 100%;
}

.joinl_search::placeholder{
  color: white;

}

a.btn.btn-primary.aanibtn {
  margin-left: 15px;
  background-color: var(--bgcolor);
  border: 1px solid var(--primarycolor);
}

a.btn.btn-primary.aadbtn{
  background-color: var(--bgcolor);
  border: 1px solid var(--primarycolor);
}

a.btn.btn-primary.aanibtn:hover{
  background: unset !important;
}

.profile_name{
  margin-left: 2rem;
  text-transform: capitalize;
}

.Position_Picklist_items {
  display: flex;
  background-color: var(--bg-color);
  color: var(--text-white);
  padding: 20px 20px 7px 20px;
  
}

.check_body.accordion-body {
  background-color: var(--bg-color);
  border: 1px solid var(--cardbg);
}

.s_m_accodion {
  background-color: var(--bg-color);
  padding: 10px 20px 0px 20px;
  /* height: 890px; */
  height: calc(100% - 64px);
  overflow-y: auto;
  scrollbar-width: auto;

}
.check_body.accordion-body {
  min-height: 665px;
  max-height: 500px;
  overflow-y: auto;
}

 .check_body.accordion-body::-webkit-scrollbar {
  width: 3px;
  height: 8px;
  background-color:#000a16; 
}


.check_body.accordion-body::-webkit-scrollbar-thumb {
  background:  #253b57;;
  border-radius:5px;
}

.allplayers_list {
  background-color: var(--bg-color);
  color: white;
  padding-top: 20px;
  /* height:825px; */
  height: calc(100% - 130px);
}

.Position_seconddivvvvvv_players {
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-bottom: 22px;
  gap: 98px;
}
.srnumber{
  font-size: 1.2rem;
  margin-right: 1rem;
  width: 20px;
}

.movie_items {
  height: 759px;
  overflow-y: auto;
  scrollbar-width: none;
}
.picklist_items {
  max-height: 824px;
  /* height: calc(100% - 130px); */
    overflow-y: auto;
    scrollbar-width: auto;
    background-color: var(--bg-color);
}

.position_card {
  border-radius: 6px;
}
.s_m_list{
  border-radius: 6px;
  padding-bottom: -7px;
  border: 1px solid var(--cardbg);
  margin-top: 30px;
  height: calc(100% - 30px);
}
.profile_namee{
  margin-left: 1rem;
}

.aaanibtn{
  background-color: var(--bgcolor) !important;
  border: 1px solid var(--primarycolor) !important;
}

.heading_div{
  gap: 28px;
}
.listcategory span{
  display: inline-block;
  margin-right: 5px;
}
.listcategory span:not(:last-child):after{
  content: ',';
}
.joinleaguemovies{
  min-height: 810px;
  max-height: 800px;
  overflow: auto;
  width: 100%;
}


.joinactionbtns .btn:first-child{
  margin-right: 5px;
}
.joinleaguetable td, .joinleaguetable th{
  background-color:  var(--bg-color);
  padding: 10px 0;
  border-bottom: 1px solid var(--primarycolor);
}
.joinleaguetable thead{
  position: sticky;
  top: 0;
}
button.btn.btn-primary.aanibtn {

  background-color: var(--bg-color);
  border: 1px solid var(--primarycolor);
  color: var(--text-white)l
}

button.btn.btn-primary.aadbtn {
  background-color: var(--bg-color);
  border: 1px solid var(--primarycolor);
  color: var(--text-white)l
}


.picklist_items::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

	background-color:var(--bg-color);
}

.picklist_items::-webkit-scrollbar
{
	width: 8px;
	background-color:var(--bg-color);
}

.picklist_items::-webkit-scrollbar-thumb
{

	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--cardbg);
}

.joinleaguemovies::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

  background-color:var(--bg-color);
}

.joinleaguemovies::-webkit-scrollbar
{
	width: 8px;
  height: 8px;
  background-color:var(--bg-color);
}

.joinleaguemovies::-webkit-scrollbar-thumb
{

	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--cardbg);
}

.s_m_accodion::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color:var(--bg-color);
}

.s_m_accodion::-webkit-scrollbar
{
	width: 10px;
  background-color:var(--bg-color);
}

.s_m_accodion::-webkit-scrollbar-thumb
{
	
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--cardbg);

}



.draft-input-groupp::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

  background-color:var(--bg-color);
}

.draft-input-groupp::-webkit-scrollbar
{
	width: 10px;
  background-color:var(--bg-color);
}

.draft-input-groupp::-webkit-scrollbar-thumb
{
	
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--cardbg);
  border-radius: 0px;

}

.date-1 {
  border: 1px solid #286ac0;
  border-radius: 5px;
  min-width: 90px;
  max-width: 90px;
  text-align: center;
  min-height: 55px;
  max-height: 60px;
  line-height: 12px;
}
.date-1 > h6 {
  font-size: 1.5rem;
  margin-top: 3px;
  font-weight: 700;
  margin-bottom: 3px;
}
.date-1 > h5 {
  font-size: .899rem;
  margin-bottom: 0px;
  font-weight: 400;
}

.league_timee{
  margin-left: 85px;
}

.date-1.custom-c-space {
  margin-left: 8px;
  margin-right: 8px;
}

table.w-100.players_list {
  margin-left: 20px;
  margin-bottom: 10px;
}

.joinleague_drafttime{
  align-items: center;
  display: flex;
}
.d-flex.first_Sec_Sec > p:first-child{
  width: 50px;
}
/* 
draft order list */
.draft_order_active img{
  border: 2px solid green;
}
.draft_order_active{
  opacity: 1;
}

.draft_order_deactive{
  opacity: 0.3;
}

.draft_order_notpick img{
  border: 2px solid red;
}
.draft_order_notpick{
  opacity: 0.3;
}

.ggg {
  width: 120px;
  min-width: 120px;
  text-align: center;
}
.draft-main{
  width: 100%;
  overflow: auto;

}
.draft-main::-webkit-scrollbar{
  display: none;
}
.draft-wrapper{
  background-color: var(--bg-color);
  display: flex;
}
.nextprebtn{
  background: transparent;
  border: none;
  color: var(--text-white);
  width: 40px;
  display: flex;
  min-width: 40px;
  align-items: center;
  justify-content: center;
}
.nextprebtn path{
  fill: currentColor;
  width: 20px;
}

.btnaoraa {
  display: flex;
  gap: 15px;
}

.ordercompleted{
 
  align-items: center;
  color: #fff;

  justify-content: center;

}
.draft-order-with-time{
display: flex;
gap: 50px;
align-items: center;
}



.joinactionbtns{
  display: flex;
  width: 250px;
  margin-left: 20px;
}



.playerinfo_img{
  margin: 5px 0px;
}

.joinleaguemovies table{
  overflow: auto;
  width: 100%;
}

.search_div input :focus{
  box-shadow: none !important;
  outline: none !important;

}
.joinactionbtnss {
  margin: 1px 24px;
}

input.form-control.mr-sm-2.joinl_search:focus{
  outline: none !important;
  border:1px solid var(--primarycolor)
}

input.form-control.mr-sm-2.joinl_search:focus{
  box-shadow: none !important;
  border:1px solid var(--primarycolor);
}

.second_position select:focus{
  box-shadow: none !important;
  border:1px solid var(--primarycolor);
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis span:hover .hidden {
  display: block;
}

.listcategory{
  white-space: normal;
}

.d-flex.first_Sec_Sec>p:last-child {

  width: calc(100% - 50px);
  
  }

  .player_info{
 height: calc(100% - 30px);

padding-bottom: 10px;

background: var(--bg-color);
  }

  .name_profile{
    text-transform: capitalize;
  }

  .text_context{
    text-transform: capitalize;
  }

  .user_name{
    text-transform: capitalize;
  }

  .ordercompleted{
    margin:0 auto;
  }

  .leaguedatetime2{
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px 30px;
  }