.Position_seconddiv{
    background-color: var(--bg-color);
    color: var(--text-white);
    padding-top: 15px;
    padding: 10px 48px;

}
.nav-position{
    background-color: var(--cardbg);
    padding: 20px;
    height: 60px;
}
.position_card{
    border: 1px solid var(--cardbg);
    margin-top: 28px;

}

.hello{
    margin-left: 10px;
}
.position_cardd{
    border: 1px solid var(--cardbg);
    margin-top: 20px;
}

.Player_titlee{
    background-color: var(--bg-color);
    color: var(--text-white);
    
    padding: 10px 10px 10px 10px;
}
.scoree{
    color: rgb(18, 224, 18);
}
.scorered{
    color: #ff0000;
}

.heading_live_list{
    color: var(--text-white);
   
        display: flex;
        justify-content: space-between;
        align-items: center;
    
}
.trade{
    display: flex;
    justify-content: space-between;
}

.mymovies_nav-position{
    display: flex;
    gap: 88px;
    
    
}
.compare_list_div{
    padding-top: 20px;
    padding-bottom: 50px;
}



.Position_seconddivv{
    display: flex;
    justify-content: space-between;
    background-color: var(--bg-color);
    color: var(--text-white);
    padding: 20px;
    border-bottom: 1px solid var(--primarycolor) !important;
}

.nav_right_title{
    display: flex;
    justify-content: right;
}

.nav-positionn{
    background-color: var(--cardbg);
    justify-content: center;
    padding: 20px;
}

.profileicon{
    width: 30px;
    height: 30px;
    object-fit: cover;
}

td.itemss img {
    margin: 3px 0px;

}

.campareicons{
    width: 24px;
    height: 15px;
    fill: var(--text-offwhite);
    margin-right: 38px;
    cursor: pointer;

}

.plyater_title_under {
    display: flex;
    gap: 90px;
}
.player_graph{
    text-align: center;
}



.Player_list::-webkit-scrollbar {
    display: none;  
  }
  
  .css-tqj8bk-MuiResponsiveChart-container{
    padding-left: 40px !important;
  }


  .table-container {
    padding: 20px;
    height: 155px;
    overflow-y: auto;
    background-color: var(--bg-color);
    color: var(--text-white);
}

.all_playerlist {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--bg-color);
    color: var( --text-offwhite);
}

td.itemss.namesforsummary {
    width: 24%;
}

td.itemss.profileforsummary{
    width: 22%;
}
.playerlist_gap {
    display: flex;
    gap: 128px;
}

.movielist_items {
    background-color: var(--bg-color);
    min-height: 65px;
    max-height: 325px;
    overflow-y:scroll;
}


.movielist_items::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #000a16;
 
  border-radius: 0px 0px 5px 5px;
}

.movielist_items::-webkit-scrollbar
{
	width: 10px;
  background-color:var(--bg-color);
}

.movielist_items::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color:var(--cardbg);
  border-radius: 0px;

}
.score_graph{
    background-color: var(--text-white);
}

.items-name{
    width: 47%;
}

.nameitems {
    width: 21%;
}
.items-name{
text-align: center;
}
.score {
    text-align: end;
    color: rgb(18, 224, 18);
}

.your-position{
    width: 50px;
}



.manage_movies-btns{
    background-image: var(--btn-bg) !important;
    background-color: transparent !important;
    color: white;
    border: none;
    border-radius: 5px;
}

.dropdown-item:hover{
    background-color: var(--text-primarycolor);
    color: white;
}

.table-containerr{
    min-height: 100px;
    max-height: 400px;
    overflow-y: auto;
    background-color: var(--bg-color);
    color: var(--text-white);
    position: relative;
}

.movieslist_headings td{
    background-color: var(--cardbg);
    border: none !important;
    height: 50px;
}

.Player_title td{
    border-bottom: 1px solid var(--primarycolor);
    height: 50px;
}

.Player_title td {
    padding-left: 10px;
}


.table-containerr::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

  background-color:var(--cardbg);
}

.table-containerr::-webkit-scrollbar
{
width: 10px;
  background-color:var(--bg-color);
}

.table-container::-webkit-scrollbar-thumb
{
	
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
background-color: var(--cardbg);
  border-radius: 0px;

}
.table-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

  background-color:var(--bg-color);
}

.table-container::-webkit-scrollbar
{
	width: 10px;
  background-color:var(--bg-color);
}

.table-container::-webkit-scrollbar-thumb
{
	
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
background-color: var(--cardbg);
  border-radius: 0px;

}

.no_movies_found{
    color: var(--text-white);
    padding:10px;
}

.view_live-btn{
    background-color: var(--live-btn-bg);
    border: none;
    border-radius: 5px;
    width: 60px;
    color: white;
    font-size: 15px;
    font-style: italic;
}

.view_live_time{
    display: flex;
    align-items: center;
    gap: 5px;
}

.movieslist_headings td {
    padding: 0px 10px;
}

.form-check-input:focus{
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #fff;
}

.Player_title td {
    padding-left: 10px;
    /* width: 100px; */

}


.table-containerr thead {
    position: sticky;
    top: 0;
}

svg.icon_eye{
  height: 18px;
    border: none;
    cursor: pointer;
    fill: var(--primarycolor);

}

.moviesnamefordetails{
    cursor: pointer;
}

.otherplyer_list h6{
    text-transform: capitalize;
}

 .items-name{
    text-transform: capitalize;
}

.itemsss{
    text-transform: capitalize;
}

.custom-title {
    font-size: 16px;
    color: #000000;
  }


  .award-table-container {
    padding: 14px;
    min-height: 155px;
 
    background-color: var(--bg-color);
    color: var(--text-white);
}