.para_privacy {
    color: var(--text-offwhite);
  }
  .para_privacy1 {
    color: var(--text-offwhite);
    padding-top: 10px;
  }


.parent_ppolicy {
  background-color: var(--bg-color);
  color: var(--text-white);
  padding: 25px;
}

.privacy_policytag{
  color: var(--text-white) !important;
}