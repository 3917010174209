.sbtext {
  color: rgb(255, 255, 255);
  text-align: center;

  @media (max-width: 425px) {

  }
}
.sbbutton {
  display: block;
  width: 172px;
  height: 61px;
  border-radius: 5px;


  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  color: var(--text-white);
  border: none;
}

.main-bsimg {
  background-color: var(--bg-color);

  padding: 60px 0px 0px 0px;
}
.sbsheading {
  letter-spacing: 8px;
}
.sbfheading {
  letter-spacing: 4px;
}
.sbtheading {
  letter-spacing: 2px;
}

.Bannersecond {
  background-image: url("../../assets/homebannerimage2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 157px 22px 100px;
  height: 400px;
}



.banner-content {
  display: flex;
  justify-content: flex-end;
}