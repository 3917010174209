
.invites_btn{
    display:flex;
    justify-content:center;
    margin-top: 30px;
  }
.Checkbox_div{
    min-height: 125px;
    max-height: 345px; 
    overflow-y: auto; 
    margin-top: 0px;
}
.check_div
{
    padding-left: 20px;
}
.search-container{
width: 300px;
}
.user_list{
    margin-top: 5px;
    padding-left: 20px;
}

.invites_main{
    padding-top: 15px;
}
.invites_main .MuiFormControl-fullWidth .MuiInputBase-fullWidth{
    height: 200px;
    padding-top: 29px;
    align-content: baseline;
}

