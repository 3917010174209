.icon {
  margin-right: 65px;
  font-size: 32px;
  color: rgb(255, 255, 255);
}

.searchbar {
  border: 1px solid var(--text-white) !important;
  width: 240px;
  padding-left: 35px;
  color: white !important;
}
input.searchbar:focus{
  outline: none !important;
  background: transparent !important;
  border: 1px solid var(--text-white) !important;
}
.searchi-filed {
  position: relative;
}

.searchi-filed input:focus
{
box-shadow: none !important;
}.search-icon {
  position: absolute;
  top: 6px;
  left: 6px;
}
.navbtnbtn {
  background: var(--btn-bg) !important;
  margin-top: 5px;
  width: 120px;
  border: none;
  height: 40px;
}
.navbar_main {
  width: 100%;
  background-color: rgb(0, 0, 0) !important;
  position: relative;
}

.navdropdown {
  color: rgb(255, 255, 255);
  margin-left: 60px;
}

.searchbar::placeholder {
  color: rgb(255, 255, 255);
  opacity: 1;
}
.searchbar {
  background-color: var(--bg-color);
}
.movieslink {
  text-decoration: none;
  color: white;
  font-weight: 500;
}
 .is_active{
  text-decoration: none;
  color: var(--text-primarycolor);

  font-weight: 500;
}
.navbar-expand-lg .navbar-collapse {
  display: flex !important;
  justify-content: space-evenly;
  flex-basis: auto;
  flex-wrap: wrap;
}
.nav_items {
  display: flex;
  justify-content: space-between;
 
  align-items: center;
} 

/* dashboard after login items css */
.div-dash {
  color: white;
  background-color: var(--blackbg) !important;
  height: 80px;
  width: 100%;
  display: inline-flex;

  justify-content: space-between;
  align-items: center;
}

.alert-icon svg {
  margin-left: 10px;
}

.drop-downicon {
  transform: rotate(90deg);
}
.profileicon {
  border-radius: 50%;
  margin-right: 10px;
  height: 50px;
  width: 50px;
}
.items {
  display: inline-flex;
  justify-content: space-between;
}

.dropdown-menu.notification-dropmenu {
  transform: none !important;
  top: 50px !important;
  right: 15px !important;
  left: inherit !important;
  background-color: white;
  width: 353px;
  max-height: 239px;
  overflow: auto;
}

.dropdown-menu.notification-dropmenu::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

  background-color:#fff;
}

.dropdown-menu.notification-dropmenu::-webkit-scrollbar
{
	width: 10px;
  background-color:#fff;
}

.dropdown-menu.notification-dropmenu::-webkit-scrollbar-thumb
{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--cardbg);
  border-radius: 0px;
}


.notifiction_item{
  color: black ;
  white-space: normal;
  font-size: 14px;
  padding: 10px 15px;
}
.notificationitem_div:hover .notifiction_item{
  color: var(--text-white);
}
.notificationitem_div{
  border-bottom: 1px solid var(--text-offwhite);
  padding: 0;
}
.new-btn{
  background-color: var(--live-btn-bg);
    border: none;
    border-radius: 5px;
    width: 40px;
    color: white;
    font-size: 12px; 
}
.alert-icon {
  display: flex;
  align-items: center;
}

.dropdown-menu.arrow_dropmenu{
  transform: none !important;
    top: 70px !important;
    right: 25px !important;
    left: inherit !important;
    background-color: white;
    --bs-dropdown-min-width:5rem !important;
    padding:0px !important;  
}

.dropdown-hover{
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}
.arrow_link {
  color: black;
  width: 100%;
  text-decoration: none;
  margin-left: 10px;
  border: none;
  padding-right: 15px;
  display: block;
  height: 100%;
  margin-top: 12px;
}

svg#dropdownMenuButton {
  margin-right: 12px !important;
}
.dropdown-hover a:hover{
  color: #fff;
}
.dropdown-hover:hover {
  background-color: var(--text-primarycolor);
  color: #fff;
}
.arrow_link:hover{
  color: var(--text-white);
}

.dropdown-menu{
  padding: 0px !important;
}
.alert-icon .badge {
  position: absolute;
  right: 8px;
  border-radius: 50%;
  background: red;
  color: white;
  top: -4px;
}


.side-nav {
width: 200px;
background:  var(--blackbg);
border-radius: 5px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(1.9px);
-webkit-backdrop-filter: blur(1.9px);
border: 1px solid var(--text-white);
position: absolute;
right: -200px;
top: 99%;
transition: all ease-in-out 0.5s;
z-index: 999;
}
.nav_items .nav_slider_btn{
  display: none;
}
.side-nav-collpase{
  right: 23px;
  display: block;
}


.nav_items .nav_slider_btn {
  background: transparent;
  height: 39px;
  width: 39px;
  border: 1px solid #fff;
  border-radius: 4px;
}

.nav_items .nav_slider_btn i {
  font-size: 24px;
  margin-top: 0px;
  color: #fff;
}



.side-nav-bar-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}


.navbar-content {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 13px;
}

.nav-content-itmes{
  display: flex;
  align-items: center;
}

.hide-btn{
  display: none !important;
}


.offcanvas_body{
  background-color: var(--blackbg);
}


.close_btn_offcanvas{
  background-color: var(--blackbg);

}

.btn-close{

  --bs-btn-close-color: #000;
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
    --bs-btn-close-opacity: 1;
    --bs-btn-close-hover-opacity: 1;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: var(--bs-btn-close-opacity);
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm{

  --bs-offcanvas-width: 280px;
}

svg#dropdownMenuButton{
  cursor: pointer;
}

.watchlist-icon> svg {
  width: 25px;
}
.watchlist-icon{
  position: relative;
  cursor: pointer;
}
.watchlist-icon span {
  width: 21px;
  height: 20px;
  background: red;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  top: -10px;
  right: -12px;
}

.navlogo{
  cursor: pointer;
}

.navlogo-text{
  cursor: pointer;
}