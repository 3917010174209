.after-nav-dsubheader {
  padding-top: 20px;
  background-color: var(--bg-color);
}

.dleague-card-img {
  width: 111px;
  height: 113px;
  border-radius: 12px;
  object-fit: cover;
}
.league-card-content {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 8px 26px;
}
.PP-btn {
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  width: 110px;
}
.d-Cardf {
  background-color: var(--cardbg);
  color: white;
  margin-top: 30px;
}
.JL-btn {
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  white-space: nowrap;
}

.dleague-card-hr {
  color: white;
  margin: 0px;
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.dleague-card-nav {
  margin: 20px 25px 10px 25px;
}
.dleague-card-nav-span {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.imag-cardclass {
  flex: 0 !important;
}
.timeicon-div {
  display: flex;
  align-items: center;

}
.after_row_div {
  padding-top: 15px;
  padding-bottom: 20px;
  padding-left: 25px;
}
.tags {
  display: flex;
  column-gap: 5px;
  justify-content: space-between;
}
.afterdashboard_nav {
  min-height: calc(100vh - 462px);
 
}

.time{
  margin: 3px;
}

.time_with_date {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.datetimeleague{
  display: flex;
  gap: 10px 30px;
  flex-wrap: wrap;
}

