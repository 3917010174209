
.main_footer{
  background: var(--cardbg);
}

.footer_container{
  display: flex;
  flex-direction: column;
}
.footer_column{
  display: flex;
  flex-direction: column;
}
.footer_row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top:70px;
  margin-bottom:35px;
  @media (max-width: 1024px) {
    .footer_row {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
}

.footer_a{
  color: var(--text-offwhite);
  margin-bottom: 12px;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
}

.footer_heading{
  font-size: 16px;
  color: white;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.footer_span{
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.footer_headingf4{
  color: white;

}
.footer_para{
  color: white;
}


.footer_iconlink{
  color: white;
  margin-bottom: 12px;
  font-size: 12px;
  text-decoration: none;
  &:hover {
    color: rgb(93, 63, 211);
    transition: 200ms ease-in;
  }

}
.footer_iconsitems{
  display: flex;
  flex-direction: column;
}

.footer_lowerspan{
  display: flex;
  color: white;
  justify-content: center;
  padding: 10px 0px 10px 0px;
}

.footer-hr{
  color: white;
  margin: 0px;
}
.footer_lowerdiv{
  margin: 10px 0px 10px 0px;

}
.iITwWE{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.iITwWE .top-btn{
  
  position: absolute !important; 
  bottom: 4.5rem !important;

}
.imdblogo{
  width: 50px;
}


.is_activefooterlink{
  color: var(--text-primarycolor) ;
}

.tiktok_icon{
  width: 18px;
  background: #fff;
  padding: 20px;
  border-radius: 50%;
}