.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination a {
  font-size: 15px;
  color: var(--text-white) !important;
  float: left;
  padding: 8px 16px;
  text-decoration: none;  
}

.active_pagination{
  background-image: var(--btn-bg) !important;
  background-color: transparent !important;
  border-color: transparent;
  border-radius: 50px;
}
