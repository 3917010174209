.m_details {
  background-color: var(--bg-color);
}
.M_details_Card {
 height: 100%;
  background-color: var(--cardbg);
  color: var(--text-white);
  border-radius: 5px;
  padding: 25px ;


}
.aadbtn {
  background-color: #3d5a7e;
  border: none;
}
.aanibtn {
  background-color: #3d5a7e;
  border: none;
}

.bbtnaora {
  display: flex;

  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.M_de_card_para {
  margin-top: 30px;
}
.t_score {
  color: #3389f6;
}
.details_head_Para {
  display: inline-block;


}
.M_de_card_scores {
  margin-top: 30px;
  display: flex;
justify-content: space-between;
flex-wrap: wrap;
}

.M_details_Se_card {
  background-color: var(--cardbg);
  color: var(--text-white);
  min-height: 150px;
  max-height: auto;
  overflow: auto;
  border-radius: 0px 0px 5px 5px;
}

.H_forbold {
  font-weight: bold;
}
.head_w_para {
  display: flex;
  align-items: baseline;
  gap: 8px;
}
.card_m_video {
  background-image: url("../../../assets/home-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  background-position: 0 35%;
  border-radius: 10px;
}
.row_cards {
  padding-top: 35px;
}
.video_icon {
  position: absolute;
  right: 1185px;
  top: 78%;
  transform: translateY(-550%);
}
.Upcom_M_con {
  color: var(--text-white);
  margin: 35px 20px 20px 20px;
}
.mmmovielistcard {
  width: 19%;
  background-color: var(--bg-color);
  color: white;
}
.cccarddm {
  justify-content: space-evenly;
  background-color: var(--bg-color);
  --bs-card-spacer-x: 0rem !important;
  padding: 7px;
  --bs-card-border-radius: none !important;
}

.cccarddm .cccardimg {
  border-radius: 10px;
  height: 400px;
  object-fit: cover;
  cursor: pointer;
  
}
.Cards_M {
  display: flex;
  gap: 23px;
  padding-top: 5px;
}


.para_t_Score {
  color: var(--text-offwhiteg);
}

.btn.cat {
  border: none;
  text-decoration: none;
  color: white;
  text-align: center;
}
.div_items {
  display: flex;

  justify-content: space-between;
  width: 17%;

  color: white;
}
.cat.active {
  border: none;
  border-radius: 5px 5px 0px 0px;
}
.card-header {
  border-bottom: 1px solid gray;
  background-color: var(--cardbg);
}
.c_header {
  display: flex;
  margin-top: 35px;

  border-radius: 5px 5px 0px 0px;
}
.cast_card {
  background-color: var(--cardbg);
  color: var(--text-white);
  border: none;
  width: 9rem;
}
.cast_crew_body {
  display: flex;
  gap: 22.5px;
  padding: 30px;
}
.cast_card_img {
  border-radius: 5px;
}
.awards_para {
  color: var(--text-offwhiteg);
}
.awards {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Reviews {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.icon11 {
  color: yellow;
}
.para_reviews_div {
  width: 85%;
}

.crew_exta_data {
  padding-left: 1rem;
}
.cardd_m_video {
 
  height: 400px;
 
}
.vvideo_icon {
  position: absolute;
  right: 1360px;
  top: 132%;
  transform: translateY(-550%);
}
.vvvideo_icon {
  position: absolute;
  right: 450px;
  top: 132%;
  transform: translateY(-550%);
}
.carddd_m_video {
  background-image: url("../../../assets/j3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  background-position: 0 10%;
  width: 910px;
}
.video_cards {
  gap: 14px;
}
.photo_div_images {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.second_review {
  padding-left: 60px;
}
.ssecond_review {
  padding-left: 70px;
}
.mmcard-btn,
.mmcard-btn:hover {
  width: 92%;
  display: none;
  color: white;
  border: solid 1px white;
  z-index: 1;
  margin-left: 9px;
  border-radius: 5px;
  background-color: #ffffff73;
}

.cccarddm:hover {
  transition: all 0.3s ease;
  filter: grayscale(5%);
  transform: scale(0.95);
}
.cccarddm:hover .mmcard-btn {
  display: block;
  filter: grayscale(1%);
  transform: scale(1);
}
.mmcard-btn {
  position: absolute;
  top: 320px;
}



.movie-details-content{
  height: 100%;
  padding: 0px 0px 0px 11px;
  border-radius: 5px;
}



span.btn.btn-primary.aadbtn_category {
  background-color: #3D5A7E;
}

span.btn.btn-primary.aadbtn_category:hover {
  background-color: #3D5A7E !important;
}

.imdbrating_tag{
  text-decoration: none;
  
}

.imdblogo_d{
  width: 35px;
  height: auto;
}


 .M_de_card_scoress
 {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
 }
 

 .moviedetails_banner{
  border: 1px solid var(--bg-color);
  border-radius: 5px;
  height: 100%;
 }

 .moviesd_bannerimg{
 border-radius: 5px;
 }

 .total_score_red{
   color: red ;
      font-weight: 800;
	    font-size: 60px;
	    text-align: center;
	    margin: 0;
 }

 .total_score_green{
  color: green;
  	font-weight: 800;
	    font-size: 60px;
	    text-align: center;
	    margin: 0;

 }
 .total_score_yellow{
  color: yellow;
  	font-weight: 800;
	    font-size: 60px;
	    text-align: center;
	    margin: 0;

 }

 .slick-slide.slick-active.slick-current:active {
  border: none !important;
}
.slick-slide.slick-active.slick-current:hover {
  border: none !important;
}

.details_total_para{
  background: #3d5a7e;
	padding: 12px;
	border-radius: 10px;
	margin-left: auto;
}

.movies_cardbody h5{
  cursor: pointer;
}

.cccarddm.mmmovielistcard.col-md-2:focus-visible{
  border: none !important;
}
.moviedetails_addtowatchlist{
  color: var(--text-white);
  margin-top: 10px;
    border: solid 1px var(--text-white);
    padding: 10px 10px;
    border-radius: 5px;
    background-color: #ffffff73;
}